import { Menu, MenuItem, Chip as MuiChip } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import React, { useState } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';


const ChipSelector = (props) => {
  const classes = useStyles();
  const { data, label } = props;
  
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRedirect = (event, link) => {
    event.stopPropagation();
    window.open(link, '_blank');
  }

  if (!data.length) {
    return (<></>)
  }

  const isSingleData = data.length === 1;

  const handleClick = (event, link) => {
    event.stopPropagation();
    if (isSingleData) {
      window.open(link, '_blank');
    } else {
      setAnchorEl(event.currentTarget);
    }
  }

  return (
    <>
      <MuiChip 
        label={isSingleData ? data[0].name : t(label, { number : data.length })} 
        className={classes.chip} 
        deleteIcon={isSingleData ? <></>: <ArrowDropDown id="arrow-drop-down" />} 
        onDelete={(event) => handleClick(event, isSingleData && data[0].link)}
        onClick={(event) => handleClick(event, isSingleData && data[0].link)}
      />
      {
        !isSingleData && (
          <Menu
            id="menu"
            anchorEl={anchorEl}
            onClose={handleClose}
            open={open}
            className={classes.dropDownMenu}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {
              data.map((item, index) => (
                <MenuItem key={index} onClick={(event) => handleRedirect(event, item.link)}>
                  { item.name }
                </MenuItem>
              ))
            }
          </Menu>
        )
      }
    </>
  )
}

export default ChipSelector;