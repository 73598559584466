import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(2),

    '& .MuiLink-root:hover': {
      cursor: 'pointer'
    }
  },
  contentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom : theme.spacing(2),
    marginTop    : theme.spacing(3)
  },
  showEventButton: {
    borderRadius: theme.spacing(1),
    marginBlock: theme.spacing(1),
    width : 'fit-content',
  },
  mainPaper: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  fakeGrid: {
    width: '100%'
  },
  squareImage: {
    height    : theme.spacing(27.5),
    marginLeft: theme.spacing(1.8),
    marginTop : theme.spacing(0.5),
    marginBottom : theme.spacing(2),
    width     : theme.spacing(30)
  },
  rectangularImage: {
    height    : theme.spacing(20),
    marginLeft: theme.spacing(3),
    marginTop : theme.spacing(1.5),
    marginBottom : theme.spacing(2),
    width     : theme.spacing(32)
  },
  textTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  link: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    width: 'fit-content'
  },
  hidden: {
    display: 'none'
  },
  onlineText:{
    color: colors.SUCCESS
  },
  offlineText:{
    color: colors.ERROR
  },
  properties: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),

    '& .MuiTypography-root': {
      marginBottom: theme.spacing(1)
    }
  },
  statistics: {
    marginBottom: theme.spacing(1),

    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: colors.SESAMSEC_SYS_LIGHT_SURFACE_CONTAINER_LOWEST
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: colors.SESAMSEC_SYS_LIGHT_ON_SURFACE_VARIANT
    }
  },
  stateValue: {
    fontWeight: 'bold'
  },
  button: {
    width : 'fit-content',
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  divider:{
    marginBlock: theme.spacing(2)
  },
  newControllerDivider: {
    marginBottom: theme.spacing(2)
  }

}));