
import DateFnsUtils from '@date-io/date-fns';
import { Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import { Form, Formik } from 'formik';
import i18n from 'i18next';
import cookies from 'js-cookie';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router-dom';
import DatePicker from '../../components/date-picker';
import Title from '../../components/title';
import UnsavedModal from '../../components/unsaved-modal';
import { AuthContext } from '../../context/authContext';
import { UPDATE_DEFAULT_VALIDITY } from '../../reducer/authReducer';
import { UpdateCurrentAdminAccount } from '../../service/administratorsApi';
import { getControllerConfigurations, updateControllerConfiguration } from '../../service/controllerConfigurationApi';
import { getEventConfigurations, updateEventConfigurations } from '../../service/eventsApi';
import { getValidityDateConfigurations, updateValidityDateConfiguration } from '../../service/validityDateConfigurationApi';
import { API_REQUEST_ERROR_MESSAGE, CONTROLLERS_MODULE, DATE_FORMAT_YYYY_MM_DD, EVENT_CONFIGURATIONS_MODULE, GET, HOURS, LANGUAGE_DE, LANGUAGE_EN, SETTINGS_CONFIGURATION } from '../../utility/constants';
import { settingSchema } from '../../validation/schema';
import SettingsSkeleton from './settings-skeleton';
import useStyles from './styles';
import EventExpirationModal from '../../components/event-expiration-modal';


const ChipDetails = (props) => {
  const { name, description} = props;
  const classes = useStyles();

  return (
    <>
      <Typography className={`bold ${classes.contentSpacing}`}>
        {name}
      </Typography>
      <Typography className={classes.contentSpacing}>
        {description}
      </Typography>
    </>
  )
}

const Content = (props) => {
  const { initialValues, handleSubmit, onChange, onDatepartChange, showButton, handleHideButton, datepart, handleChange,
    expiration, requestLimit, setValidFrom, setIsNotValid, setValidUntil, language, hasEventConfigurationPermission, hasControllerPermission,
    isLoadingControllerConfiguration, isLoadingEventConfiguration, isLoadingValidityDate, isLoading } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return(
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={settingSchema}
      onSubmit={handleSubmit}
      >
      {
        formik => (
          <Container maxWidth="xl" className={classes.container}>
            <Title title={t('settings-page.settingsTitle')}/>
            <Form>
              <Paper className={`${classes.paper} ${classes.paperContent}`} elevation={3}>
                {
                  isLoading ? <SettingsSkeleton chip={SETTINGS_CONFIGURATION.LANGUAGE}/> :
                  <>
                    <ChipDetails
                      name={t('settings-page.language')}
                      description={t('settings-page.languageDescription')}
                    />
                    <FormControl variant="outlined" className={classes.contentSpacing}>
                      <InputLabel>{t('settings-page.language')}</InputLabel>
                      <Select
                        id="settingsLanguage"
                        value={language}
                        onChange={onChange}
                        label="language"
                        size="medium"
                        className={classes.selectWidth}
                      >
                        <MenuItem id="settingsLanguageEnglish"value={LANGUAGE_EN}>English</MenuItem>
                        <MenuItem id="settingsLanguageDeutsch"value={LANGUAGE_DE}>Deutsch</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                }
              </Paper>
              <Paper className={`${classes.paper} ${classes.paperContent}`} elevation={3}>
                {
                  isLoadingValidityDate ? <SettingsSkeleton chip={SETTINGS_CONFIGURATION.VALIDITY_DATES}/> :
                  <>
                    <ChipDetails
                      name={t('settings-page.validityDates')}
                      description={t('settings-page.validityDatesDescription')}
                    />
                    <Grid className={classes.event} item xs={12} sm={12} md={12} lg={12}>
                      <FormControl variant="outlined" className={classes.contentSpacing}>
                        <DatePicker
                          name="settingsValidFrom"
                          label={t('validFrom')}
                          until={formik.values.validUntil}
                          value={formik.values.validFrom}
                          handleChange={setValidFrom}
                          touched={formik.touched.validFrom}
                          error={formik.errors.validFrom}
                          setIsNotValid={setIsNotValid}
                          className={classes.selectWidth}
                        />
                      </FormControl>
                      <FormControl variant="outlined" className={classes.contentSpacing}>
                        <DatePicker
                          name="settingsValidUntil"
                          label={t('validUntil')}
                          min={formik.values.validUntil}
                          from={formik.values.validFrom}
                          value={formik.values.validUntil}
                          handleChange={setValidUntil}
                          touched={formik.touched.validUntil}
                          error={formik.errors.validUntil}
                          setIsNotValid={setIsNotValid}
                          className={classes.selectWidth}
                        />
                      </FormControl>
                    </Grid>
                  </>
                }
              </Paper>
              <Paper className={clsx(hasEventConfigurationPermission ? `${classes.paper} ${classes.paperContent}` : 'hidden')} elevation={3}>
                {
                  isLoadingEventConfiguration ? <SettingsSkeleton chip={SETTINGS_CONFIGURATION.EVENT_CONFIGURATION}/> :
                  <>
                    <ChipDetails
                      name={t('settings-page.eventsHistory')}
                      description={t('settings-page.eventsHistoryDescription')}
                    />
                    <Grid item className={classes.event} xs={9} sm={9} md={9} lg={9}>
                      <Typography className={`${classes.contentSpacing} ${classes.centerText} ${(formik.errors.expiration ? classes.errorText : '')}`}>{t('settings-page.deleteEvents')}</Typography>
                      <FormControl variant="outlined" className={classes.contentSpacing}>
                        <TextField
                          id="settingsEventExpiration"
                          size="medium"
                          name="expiration"
                          type="number"
                          min="0"
                          onChange={handleChange}
                          value={expiration}
                          className={classes.configurationWidth}
                          error={formik.touched.expiration && Boolean(formik.errors.expiration)}
                        />
                      </FormControl>
                      <FormControl variant="outlined" className={classes.contentSpacing}>
                        <InputLabel>{t('duration')}</InputLabel>
                        <Select
                          id="settingsEventExpirationDatepart"
                          value={datepart}
                          onChange={onDatepartChange}
                          label="datepart"
                          size="medium"
                          className={classes.selectWidth}
                        >
                          <MenuItem id="settingsEventExpirationHour" value="HOUR">{t('hours')}</MenuItem>
                          <MenuItem id="settingsEventExpirationDay" value="DAY">{t('days')}</MenuItem>
                          <MenuItem id="settingsEventExpirationMonth" value="MONTH">{t('months')}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Typography className={`${classes.errorText} ${classes.errorStyle}`}>{t(formik.touched.expiration) && t(formik.errors.expiration)}</Typography>
                  </>
                }
              </Paper>
              <Paper className={clsx(hasControllerPermission ? `${classes.paper} ${classes.paperContent}` : 'hidden')} elevation={3}>
                {
                  isLoadingControllerConfiguration ? <SettingsSkeleton chip={SETTINGS_CONFIGURATION.CONTROLLER_CONFIGURATION}/> :
                  <>
                    <ChipDetails
                      name={t('settings-page.requestLimit')}
                      description={t('settings-page.requestLimitDescription')}
                    />
                    <Grid item className={classes.event} xs={9} sm={9} md={9} lg={9}>
                      <FormControl variant="outlined" className={classes.contentSpacing}>
                        <TextField
                          id="settingsRequestLimit"
                          size="medium"
                          name="requestLimit"
                          type="number"
                          min="0"
                          onChange={handleChange}
                          value={requestLimit}
                          className={classes.configurationWidth}
                          error={formik.touched.requestLimit && Boolean(formik.errors.requestLimit)}
                        />
                      </FormControl>
                      <Typography className={`${classes.contentSpacing} ${classes.centerText} ${(formik.errors.requestLimit ? classes.errorText : '')}`}>{t('settings-page.request')}</Typography>
                    </Grid>
                    <Typography className={`${classes.errorText} ${classes.errorStyle}`}>{t(formik.touched.requestLimit) && t(formik.errors.requestLimit)}</Typography>
                  </>
                }
              </Paper>
              <Grid container className={classes.form} spacing={2}>
                <Grid item xs={4} sm={4} md={8} lg={8}></Grid>
                <Grid className={classes.updateCancelButtonContainer} spacing={2}>
                  <Grid item className={classes.updateCancelButton} xs={2} sm={2} md={2} lg={2}>
                    <Button
                      id="settingsCancelButton"
                      className={classes.button}
                      variant="outlined"
                      color="primary"
                      onClick={handleHideButton}
                      disabled={showButton}
                    >
                      {t('cancel')}
                    </Button>
                  </Grid>
                  <Grid className={classes.updateButton} item xs={2} sm={2} md={2} lg={2}>
                    <Button
                      id="settingsSubmitButton"
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={showButton}
                    >
                      {t('update')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </Container>
        )
      }
    </Formik>
  )
}

const Settings = (props) => {
  const currentLanguageCode    = cookies.get('i18next') || LANGUAGE_EN;
  const { showToaster, handlePermissions } = props;

  const { dispatch, state: authState } = useContext(AuthContext);
  
  const { t }   = useTranslation();
  
  const history = useHistory();
  
  const [language, setLanguage]         = useState(currentLanguageCode);
  const [datepart, setDatepart]         = useState(HOURS);
  const [expiration, setExpiration]     = useState(0);
  const [showButton, setShowButton]     = useState(true);
  const [validFrom, setValidFrom]       = useState(moment().format('YYYY-MM-DD'));
  const [validUntil, setValidUntil]     = useState(moment().format('YYYY-MM-DD'));
  const [requestLimit, setRequestLimit] = useState(0);
  const [isNotValid, setIsNotValid]     = useState(false);
  const [showModal, setShowModal]       = useState(false);
  const [prevValues, setPrevValues]     = useState([]);
  const [withChanges, setWithChanges]   = useState(false);
  const [toRedirect, setToRedirect]     = useState('');

  const [showEventExpirationModal, setShowEventExpirationModal] = useState(false);
  
  const [isLoading, setIsLoading]                                               = useState(true);
  const [isLoadingValidityDate, setIsLoadingValidityDate]                       = useState(true);
  const [isLoadingEventConfiguration, setIsLoadingEventConfiguration]           = useState(true);
  const [isLoadingControllerConfiguration, setIsLoadingControllerConfiguration] = useState(true);
  
  const hasEventConfigurationPermission = handlePermissions(EVENT_CONFIGURATIONS_MODULE, GET);
  const hasControllerPermission = handlePermissions(CONTROLLERS_MODULE, GET);

  const administrator = { authState };

  const initialValues = {
    language     : language,
    expiration   : expiration,
    datepart     : datepart,
    validFrom    : validFrom,
    validUntil   : validUntil, 
    requestLimit : requestLimit
  }

  const handleChange = (event, setter, formik) => {
    const { value } = event.target;

    if (event.target.name === 'expiration') {
      setExpiration(parseInt(value.replace(/\D/,'')));
    } else {
      setRequestLimit(parseInt(value.replace(/\D/,'')));
    }
    
    return(setter, formik)
  }

  const handleChanges = useCallback(() => {
    if (prevValues.length === 0) return;
    if (prevValues.language      === language
      && prevValues.validFrom    === validFrom
      && prevValues.validUntil   === validUntil
      && prevValues.expiration   === expiration
      && prevValues.datepart     === datepart
      && prevValues.requestLimit === requestLimit) {
      setShowButton(true);
      setWithChanges(false);
    } else {
      setShowButton(false);
      setWithChanges(true);
    }
  }, [datepart, expiration, language, prevValues, requestLimit, validFrom, validUntil]);

  useEffect(() => {
    handleChanges();
  }, [language, expiration, datepart, validFrom, validUntil, requestLimit, handleChanges]);

  const handleHideButton = () => {
    setShowButton(true);
    getSettings();
  }

  const onChange = (event) => {
    setLanguage(event.target.value);
  }

  const onDatepartChange = (event) => {
    setDatepart(event.target.value);
  }

  const onSubmit = async () => {
    const isLanguageConfigurationChanged     = (prevValues.language === language);
    const isValidityDateConfigurationChanged = (prevValues.validFrom === validFrom) && (prevValues.validUntil === validUntil);
    const isControllerConfigurationChanged   = (prevValues.requestLimit === requestLimit);

    try {
      if (!isLanguageConfigurationChanged) {
        const { keycloakAttributes } = authState.administrator;

        const administratorObject = {
          ...keycloakAttributes,
          locale: [language]
        }

        await UpdateCurrentAdminAccount(administratorObject);
        i18n.changeLanguage(language);
        cookies.set('KEYCLOAK_LOCALE', language);
      }

      if (!isValidityDateConfigurationChanged) {
        await updateValidityDateConfiguration(validFrom, validUntil);

        dispatch({
          type    : UPDATE_DEFAULT_VALIDITY,
          payload : {
            defaultValidFrom  : validFrom,
            defaultValidUntil : validUntil
          }
        });
      }

      if (!isControllerConfigurationChanged) {
        await updateControllerConfiguration(requestLimit)
      }

      getSettings();
      setWithChanges(false);
      setShowButton(true);
      showToaster(t('success'), t('settings')+' '+t('hasBeenUpdated'), 'success');
    } catch {
      setWithChanges(true);
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }

  const handleSubmit = async() => {
    if (isNotValid) {
      return;
    }

    if (!withChanges) {
      setWithChanges(false);
      history.push('/');
      return;
    }

    const isEventConfigurationChanged = (prevValues.expiration !== expiration) || (prevValues.datepart !== datepart);
    if (isEventConfigurationChanged) {
      setShowEventExpirationModal(true);
      return;
    }
    await onSubmit();
  }

  const handleEventExpiration = async() => {
    try {
      await updateEventConfigurations('true', datepart, expiration);
      await onSubmit();
    } catch {
      setWithChanges(true);
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setShowEventExpirationModal(false);
    }
  }

  const handleEventExpirationCancel = () => {
    setShowEventExpirationModal(false);
  }

  const getEventConfiguration = useCallback(async () => {
    if (!hasEventConfigurationPermission) {
      setIsLoadingEventConfiguration(false);
      return {
        expiration: 0,
        datepart  : HOURS,
      }
    }

    const response = await getEventConfigurations();

    const eventConfigurations = response.data;

    const eventExpiration = eventConfigurations[0]?.expiration;
    const eventDatepart   = eventConfigurations[0]?.datepart;

    setExpiration(eventExpiration);
    setDatepart(eventDatepart);
    setIsLoadingEventConfiguration(false);
    return {
      expiration: eventExpiration,
      datepart  : eventDatepart,
    }
  }, [hasEventConfigurationPermission]);

  const getControllerConfiguration = useCallback(async () => {
    if (!hasControllerPermission) {
      return 0;
    }
    
    let requestLimit = 100; 

    const currentRequestLimit = await getControllerConfigurations();
    if (currentRequestLimit !== 0 || currentRequestLimit !== undefined) {
      requestLimit = currentRequestLimit;
    }

    setIsLoadingControllerConfiguration(false);
    return requestLimit;
  }, [hasControllerPermission]);

  const getValidityDatesConfiguration = async () => {

    const validityDateConfigurations = await getValidityDateConfigurations();

    const validFromConfiguration  = moment(validityDateConfigurations?.validFrom).format(DATE_FORMAT_YYYY_MM_DD);
    const validUntilConfiguration = moment(validityDateConfigurations?.validUntil).format(DATE_FORMAT_YYYY_MM_DD);

    setValidFrom(validFromConfiguration);
    setValidUntil(validUntilConfiguration);
    setIsLoadingValidityDate(false);
    return {
      validFrom : validFromConfiguration,
      validUntil: validUntilConfiguration
    }
  }

  const getSettings = useCallback(async () => {
    setIsLoading(false);
    try {
      const validityDatesConfiguration = await getValidityDatesConfiguration();
      const eventConfiguration         = await getEventConfiguration();
      const controllerConfiguration    = await getControllerConfiguration();

      const languageConfiguration = cookies.get('i18next') || LANGUAGE_EN;

      const initialSettingsData = {
        ...validityDatesConfiguration,
        ...eventConfiguration,
        language     : languageConfiguration,
        requestLimit : controllerConfiguration
      }
  
      setRequestLimit(controllerConfiguration)
      setLanguage(languageConfiguration);
      setPrevValues(initialSettingsData);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }, [showToaster, t, getControllerConfiguration, getEventConfiguration]);

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleModalSubmit = () => {
    setWithChanges(false);
    history.push(toRedirect);
  }

  const handleModalCancel = () => {
    handleChanges();
    setShowModal(false);
  }

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={currentLanguageCode === LANGUAGE_DE ? deLocale : enLocale}>
      <UnsavedModal
        open={showModal}
        onClose={handleCloseModal}
        handleModalSubmit={handleModalSubmit}
        handleModalCancel={handleModalCancel}
      />
      <EventExpirationModal
        open={showEventExpirationModal}
        handleSubmit={handleEventExpiration}
        handleCancel={handleEventExpirationCancel}
      />
      <Prompt
        when={withChanges}
        message={(location, action) => {
          if (action === 'PUSH') {
            setShowModal(true);
          }
          setWithChanges(false);
          setToRedirect(location.pathname);
          return location.pathname === '/' || location.pathname.startsWith('/settings')
        }}
      />
      <Content
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        onDatepartChange={onDatepartChange}
        onChange={onChange}
        datepart={datepart}
        expiration={expiration}
        handleChange={handleChange}
        showButton={showButton}
        handleHideButton={handleHideButton}
        administrator={administrator}
        language={language}
        setValidFrom={setValidFrom}
        setValidUntil={setValidUntil}
        setIsNotValid={setIsNotValid}
        requestLimit={requestLimit}
        hasEventConfigurationPermission={hasEventConfigurationPermission}
        hasControllerPermission={hasControllerPermission}
        isLoadingControllerConfiguration={isLoadingControllerConfiguration}
        isLoadingEventConfiguration={isLoadingEventConfiguration}
        isLoadingValidityDate={isLoadingValidityDate}
        isLoading={isLoading}
      />
    </MuiPickersUtilsProvider>
  );
}

export default Settings;