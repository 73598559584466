import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  form: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(0),
  },
  description: {
    fontSize: theme.spacing(1.75),
    color: colors.DARK_GRAY,
  }
}));