import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    flexFlow: 'column',
    padding : theme.spacing(3),
    paddingTop: theme.spacing(0)
  },
  containerItem: {
    paddingBottom : theme.spacing(0),
  },
  flexForm: {
    alignItems: 'center',
    display: 'flex',
  },
  label: {
    minWidth: theme.spacing(10),
    color: colors.DARK_GRAY_TEXT,
    fontSize: theme.spacing(1.75)
  },
  icon: {
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      color: colors.PRIMARY
    }
  },
  iconClicked: {
    cursor: 'unset',
    '& .MuiSvgIcon-root': {
      color: colors.PRIMARY
    }
  },
  qr: {
    border : `4px solid ${colors.BLACK}`,
    padding: theme.spacing(0.5)
  },
  paramItemContainer: {
    paddingBottom: theme.spacing(3)
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  value: {
    fontSize: theme.spacing(1.75),
    color: colors.DARK_GRAY,
  },
  password: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    '& .MuiSvgIcon-root': {
      color: colors.PRIMARY
    },
    '& .MuiIconButton-root': {
      padding: theme.spacing(0)
    }
  }
}));