import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  image: {
    height          : theme.spacing(48),
    marginBottom    : theme.spacing(-7.625),
    marginTop       : theme.spacing(-11.625),
    width           : theme.spacing(34.3)
  },
  textTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  properties: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),

    '& .MuiSkeleton-root': {
      marginBottom: theme.spacing(1)
    }
  },
  details: {
    marginBottom: theme.spacing(2),

    '& .MuiSkeleton-root': {
      marginBottom: theme.spacing(1)
    }
  },
  fakeGrid: {
    width: '100%'
  },
  accessPointsContainer: {
    marginBlock: theme.spacing(2)
  }
}));