import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  dialogModal: {
    alignItems    : 'center',
    borderTopLeftRadius: theme.spacing(1.25),
    borderTopRightRadius: theme.spacing(1.25),
    display       : 'flex',
    flexDirection : 'column',
    paddingBottom : theme.spacing(3),
    paddingLeft   : theme.spacing(3),
    paddingRight  : theme.spacing(2),
    paddingTop    : theme.spacing(3),
    width         : '100%'
  },
  dialogTitle: {
    alignItems    : 'center',
    display       : 'flex',
    justifyContent: 'space-between',
    marginBottom  : theme.spacing(2),
    width         : '100%',
  },
  title: {
    color     : colors.DARK_GRAY,
    fontSize  : theme.spacing(2.75),
    fontWeight: 700,
    lineHeight: '22px',
  },
  dialogContent: {
    paddingRight: theme.spacing(1)
  },
  message: {
    color     : colors.DARK_GRAY,
    fontSize  : theme.spacing(2),
    lineHeight: '24px'
  },
  dialogActions: {
    alignItems     : 'center',
    backgroundColor: colors.LIGHT_GRAY,
    display        : 'flex',
    gap            : '8px',
    justifyContent : 'flex-end',
    paddingBottom  : '26px',
    paddingLeft    : '34px',
    paddingRight   : '34px',
    paddingTop     : '26px',
    width          : '100%',
    '& .MuiButton-containedPrimary': {
      background: colors.ERROR,
      color: colors.WHITE
    }
  },
  dialogButton: {
    borderRadius : '8px',
    display      : 'flex',
    float        : 'left',
    height       : '40px',
    lineHeight   : '16px',
    padding      : '10px 20px',
    width        : 'auto'
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      width    : '430px',
      maxWidth : '100%'
    }
  },
  icon: {
    height : '36px !important',
    width  : '36px !important'
  }
}));