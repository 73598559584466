import { Container } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ChipSelector from '../../components/chip-selector';
import EnhancedTable, { createColumn } from '../../components/enhanced-table';
import { LocationContext } from '../../context/locationContext';
import useParams from '../../hooks/useParams';
import { getAccessPoint, getAccessPointListCount } from '../../service/accessPointsApi';
import { ACCESS_POINTS, ACCESS_POINTS_MODULE, ACTION_VIEW, ALL, API_REQUEST_ERROR_MESSAGE, ASCENDING, CONTROLLERS, NAME, PROFILES, PROFILES_MODULE } from '../../utility/constants';
import { parseParams } from '../../utility/helper';
import useStyles from './styles';

const columns = [
  createColumn("name", "accesspoints-page.nameColumn", true, "string", false, true),
  createColumn("id", "ID", false, "numeric", true),
  createColumn("profiles", "accesspoints-page.profilesColumn", false, "component", false, false, false, PROFILES_MODULE),
  createColumn("entries", "accesspoints-page.readersColumn", false, "component"),
  createColumn("location", "accesspoints-page.locationColumn", true, "string")
];

const initialParams = {
  listType: ALL,
  size: 100,
  page: 1,
  sort: `${NAME},${ASCENDING}`,
  keyword: ''
}

const AccessPoints = (props) => {
  const classes       = useStyles();
  const history       = useHistory();
  const { t }         = useTranslation();
  
  const [searchParams, setSearchParams] = useParams(initialParams);

  const { sort, listType: listTypeParam, size: sizeParam, page: pageParam, keyword: keywordParam } = searchParams;

  const { state : locationState }                  = useContext(LocationContext);
  const { selectedLocationIds }                    = locationState;
  const { showToaster, handlePermissions }         = props;
  const [isLoading, setIsLoading]                  = useState(false);
  const [accessPoints, setAccessPoints]            = useState([]);
  const [totalAccessPoints, setTotalAccessPoints]  = useState(0);
  const [keyword, setKeyword]                      = useState(keywordParam);
  const [isTotalItemsLoading, setIsTotalItemsLoading] = useState(false);

  let debounceTimeoutRef = useRef(null);

  const orderBy = parseParams(sort)[0];
  const order = parseParams(sort)[1];
  const listType = listTypeParam.toLowerCase();
  const size = parseInt(sizeParam);
  const page = parseInt(pageParam);

  const createProfileChipData = (profiles) => {
    if (!profiles) {
      return [];
    }
    return profiles.map(item => {
      return {
        id: item.profileId,
        name: item.name,
        link: `/${PROFILES}/${ACTION_VIEW}/${item.profileId}`
      }
    })
  }

  const createReaderChipData = (readers, controllerId) => {
    if (!readers) {
      return [];
    }

    return readers.map(item => {
      return {
        id: item.id,
        name: item.name,
        link: `/${CONTROLLERS}/${ACTION_VIEW}/${controllerId}`
      }
    })
  }

  
  const formatAccessPoints  = useCallback((data) => {
    return data.map(accessPoint => {
      const { name, controller, controllerId, location, id, profiles, readers } = accessPoint;

      const profileData = createProfileChipData(profiles);
      const readerData = createReaderChipData(readers, controllerId);

      return {
        name        : `${controller.name}:${name}`,
        id          : id,
        profiles    : <ChipSelector label={'accesspoints-page.profileChipName'} data={profileData}/>,
        entries     : <ChipSelector label={'accesspoints-page.readerChipName'} data={readerData}/>,
        location    : location ? location.name : '-',
        doorAccess  : true
      }
    });
  }, []);

  const getAccessPoints = useCallback(async (status = true) => {
    setIsLoading(status);

    try {
      const response = await getAccessPoint(keyword, size, page, orderBy, order, selectedLocationIds);

      const { accessPoints } = response;
      
      const newAccessPoints = formatAccessPoints(accessPoints);

      setAccessPoints(newAccessPoints);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }

  }, [showToaster, t, order, selectedLocationIds, keyword, orderBy, page, size, formatAccessPoints]);

  const getAccessPointCount =useCallback(async() => {
    setIsTotalItemsLoading(true);
    try {
      const response = await getAccessPointListCount(keyword, selectedLocationIds);
      const page = response.page;

      setTotalAccessPoints(page.totalElements);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsTotalItemsLoading(false);
    }
  }, [showToaster, t, selectedLocationIds, keyword])

  useEffect(() => {
    getAccessPoints();
  }, [getAccessPoints, keywordParam, selectedLocationIds]);

  useEffect(() => {
    getAccessPointCount();
    return () => {
      setIsTotalItemsLoading(0);
    }
  }, [keywordParam, selectedLocationIds, getAccessPointCount]);

  const handleSearch = (value) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    
    setIsLoading(true);
    setKeyword(value);

    debounceTimeoutRef.current = setTimeout(() => {
      setSearchParams({...searchParams, keyword: value, page: 1});
    }, 1000);
  }

  const handleClearSearch = () => {
    setKeyword('');
    setSearchParams({...searchParams, keyword: '', page: 1});
  }

  const handleSort = (newOrderBy, newOrder) => {
    setSearchParams({...searchParams, sort: `${newOrderBy},${newOrder}`, page: 1});
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setSearchParams({...searchParams, size: newRowsPerPage, page: 1});
  }

  const handleChangePage = (newPage) => {
    setSearchParams({...searchParams, page: newPage + 1});
  }

  const handleRowClick = (id) => {
    history.push(`/accessPoints/view/${id}`);
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <EnhancedTable
        title={t(ACCESS_POINTS)}
        columns={columns}
        data={accessPoints}
        isLoading={isLoading}
        isTotalItemsLoading={isTotalItemsLoading}
        keyword={keyword}
        label={ACCESS_POINTS_MODULE}
        module={ACCESS_POINTS_MODULE}
        onChangePage={handleChangePage}
        onClearSearch={handleClearSearch}
        onRowsPerPageChange={handleRowsPerPageChange}
        onSearch={handleSearch}
        onSort={handleSort}
        orderBy={orderBy}
        order={order}
        page={page}
        rowsPerPage={size}
        totalItems={totalAccessPoints}
        viewKey={"name"}
        listType={listType}
        handlePermissions={handlePermissions}
        handleRowClick={handleRowClick}
      />
    </Container>
  );
}

export default AccessPoints;