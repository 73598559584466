import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ActionModal from '../../../components/controller-modal';
import useStyles from './styles';
import { API_REQUEST_ERROR_MESSAGE } from '../../../utility/constants';
import { Form, Formik } from 'formik';
import { updateControllerSoftware } from '../../../service/controllersApi';
import { TextField, Typography } from '@material-ui/core';
import { controllerSoftwareSchema } from '../../../validation/schema';


/**
 * @param {object} props - The `ControllerParametersModal` component accepts the following props:
 * @property {boolean} isOpen - Determines if the modal is visible.
 * @property {function} onClose - Callback function triggered when the modal is closed.
 * @property {number} controllerId - Username for the controller, displayed as a parameter.
 * @returns {JSX.Element|null} The `ControllerParametersModal` component renders a modal displaying the controller's connection parameters,
 * including a QR code, URL, port, username, and password. If `isOpen` is false, the component renders `null`.
 */
const ControllerUpdateFirmwareModal = ({ 
  isOpen, 
  onClose, 
  controllerId, 
  showToaster,
  name,
  showLoading
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formikRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  if (!isOpen) return null;

  const initialValues = {
    url: ''
  };

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    try {
      await updateControllerSoftware(controllerId, values.url);
  
      showToaster(t('success'), t('controller-modal.update-firmware-success', { name: values.name }), 'success');
      onClose();
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('controller-modal.update-firmware-title')}
      cancelButtonLabel={t('controller-modal.cancel-button')}
      submitButtonLabel={t('controller-modal.save-button')}
      handleSubmit={() => formikRef.current?.handleSubmit()}
    >
      { showLoading(isLoading) }
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={controllerSoftwareSchema}
        onSubmit={handleFormSubmit}
        innerRef={formikRef}
      >
        {({ values, touched, errors, handleChange }) => (
          <Form className={classes.form}>
            <Typography className={classes.description}>
              <Trans i18nKey={'controller-modal.update-firmware-description'} values={{name}}/>
            </Typography>
            <TextField
              className={classes.url}
              data-testid="controllerUpdateModalUrl"
              label={t('controller-modal.update-firmware-input-label')}
              name="url"
              fullWidth
              value={values.url}
              onChange={handleChange}
              error={touched.url && Boolean(errors.url)}
              helperText={t(errors.url)}
            />
          </Form>
        )}
      </Formik>
    </ActionModal>
  );
};

ControllerUpdateFirmwareModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  controllerId: PropTypes.number.isRequired,
  showToaster: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  showLoading: PropTypes.func.isRequired,
};

export default ControllerUpdateFirmwareModal;