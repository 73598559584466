const images = {
  ACCESS_CHECK_EVENT         : `${window.STORAGE_ACCOUNT}/access-check-event.svg`,
  CHAMELEON                  : `${window.STORAGE_ACCOUNT}/loading.gif`,
  COMING_SOON                : `${window.STORAGE_ACCOUNT}/coming-soon.svg`,
  CONTROLLERS: {
    X2CPASS     : `${window.STORAGE_ACCOUNT}/x2cpass-controller.png`,
    SECPASS     : `${window.STORAGE_ACCOUNT}/secpass-controller.png`,
    XPASS_PLUS  : `${window.STORAGE_ACCOUNT}/xpass-plus-controller.png`,
  },
  CONTROLLER_CREDENTIALS_ICON: `${window.STORAGE_ACCOUNT}/controller-credentials-icon.svg`,
  REBOOT_CONTROLLER_SVG      : `${window.STORAGE_ACCOUNT}/controller-reboot.svg`,
  REBUILD_CONTROLLER_SVG     : `${window.STORAGE_ACCOUNT}/controller-rebuild.svg`,
  UPDATE_CONTROLLER_SVG      : `${window.STORAGE_ACCOUNT}/controller-update.svg`,
  DELETE_MODAL               : `${window.STORAGE_ACCOUNT}/delete-modal.svg`,
  DOWNLOAD_REPORT            : `${window.STORAGE_ACCOUNT}/download-report.svg`,
  EMPTY_TABLE                : `${window.STORAGE_ACCOUNT}/empty-table.svg`,
  ERROR404                   : `${window.STORAGE_ACCOUNT}/error.svg`,
  IMPORT_CSV_SVG             : `${window.STORAGE_ACCOUNT}/import-csv.svg`,
  SESAMSEC_ICON              : `${window.STORAGE_ACCOUNT}/sesamlab-icon.ico`,
  SESAMLAB_LOGO              : `${window.STORAGE_ACCOUNT}/sesamlab-logo.png`,
  SESAMSEC_LOGO              : `${window.STORAGE_ACCOUNT}/sesamsec-logo.png`,
  SIGNIN_BG                  : `${window.STORAGE_ACCOUNT}/signin-bg.png`,
  UNSAVED_CHANGES_SVG        : `${window.STORAGE_ACCOUNT}/unsaved-changes.svg`,
  SETUP_WIZARD               : `${window.STORAGE_ACCOUNT}/wizard-modal.svg`,
  SAMPLE_CSV_FILE            : `${window.STORAGE_ACCOUNT}/CSVFileSample.csv`,
  BLOCK                      : `${window.STORAGE_ACCOUNT}/block.svg`,
  ONBOARD_NEW_PERSON_SVG     : `${window.STORAGE_ACCOUNT}/onboard-new-person.svg`,
  REPLACE_LOST_CREDENTIAL_SVG: `${window.STORAGE_ACCOUNT}/replace-lost-credential.svg`,
  CLOCK                      : `${window.STORAGE_ACCOUNT}/clock.svg`,
  NO_PERMISSION_SVG          : `${window.STORAGE_ACCOUNT}/no-permission.svg`,
  NO_ROLES_SVG               : `${window.STORAGE_ACCOUNT}/no-roles.svg`,
  ERROR403                   : `${window.STORAGE_ACCOUNT}/403.svg`,
  FORBIDDEN                  : `${window.STORAGE_ACCOUNT}/forbidden.svg`,
  COPY_ICON                  : `${window.STORAGE_ACCOUNT}/copy-icon.svg`,
  COPY_ICON_HOVER            : `${window.STORAGE_ACCOUNT}/copy-icon-hover.svg`,
  VISIBILITY_ON              : `${window.STORAGE_ACCOUNT}/visibility-on.svg`,
  VISIBILITY_OFF             : `${window.STORAGE_ACCOUNT}/visibility-off.svg`,
  NO_ROLES_ASSIGNED          : `${window.STORAGE_ACCOUNT}/no-roles-assigned.svg`,
  LOCATION_ICON              : `${window.STORAGE_ACCOUNT}/location-icon.svg`,
  AREA_ICON                  : `${window.STORAGE_ACCOUNT}/area-icon.svg`,
  EDIT_ICON                  : `${window.STORAGE_ACCOUNT}/edit-icon.svg`,
  ADD_ICON                   : `${window.STORAGE_ACCOUNT}/add-icon.svg`,
  DELETE_ICON                : `${window.STORAGE_ACCOUNT}/delete-icon.svg`,
  CHECK_ICON                 : `${window.STORAGE_ACCOUNT}/check-icon.svg`,
  DELETE_MODAL_ICON          : `${window.STORAGE_ACCOUNT}/delete-modal-icon.svg`,
  DIALPAD_OFF_ICON           : `${window.STORAGE_ACCOUNT}/dialpad-off.svg`,
  BADGE_OFF_ICON             : `${window.STORAGE_ACCOUNT}/badge-off.svg`,
  ENTRAIDGUIDE: {
    CREATEDOMAIN_STEP2   : `${window.STORAGE_ACCOUNT}/entraIdGuide-createDomain-step2.png`,
    CREATEDOMAIN_STEP3   : `${window.STORAGE_ACCOUNT}/entraIdGuide-createDomain-step3.png`,
    CREATEDOMAIN_STEP4   : `${window.STORAGE_ACCOUNT}/entraIdGuide-createDomain-step4.png`,
    CREATEDOMAIN_STEP5   : `${window.STORAGE_ACCOUNT}/entraIdGuide-createDomain-step5.png`,
    CREATEDOMAIN_STEP6   : `${window.STORAGE_ACCOUNT}/entraIdGuide-createDomain-step6.png`,
    CREATEDOMAIN_STEP7   : `${window.STORAGE_ACCOUNT}/entraIdGuide-createDomain-step7.png`,
    CREATEDOMAIN_STEP8   : `${window.STORAGE_ACCOUNT}/entraIdGuide-createDomain-step8.png`,
    CREATEDOMAIN_STEP10  : `${window.STORAGE_ACCOUNT}/entraIdGuide-createDomain-step10.png`,
    SECURELDAP_STEP2_1 : `${window.STORAGE_ACCOUNT}/entraIdGuide-secureLdap-step2-1.png`,
    SECURELDAP_STEP2_2 : `${window.STORAGE_ACCOUNT}/entraIdGuide-secureLdap-step2-2.png`,
    SECURELDAP_STEP3   : `${window.STORAGE_ACCOUNT}/entraIdGuide-secureLdap-step3.png`,
    CREATEGROUP_STEP2: `${window.STORAGE_ACCOUNT}/entraIdGuide-createGroup-step2.png`,
    CREATEGROUP_STEP3: `${window.STORAGE_ACCOUNT}/entraIdGuide-createGroup-step3.png`,
    ADDMEMBER_STEP2  : `${window.STORAGE_ACCOUNT}/entraIdGuide-addMembers-step2.png`,
    ADDMEMBER_STEP3  : `${window.STORAGE_ACCOUNT}/entraIdGuide-addMembers-step3.png`,
    ADDMEMBER_STEP4  : `${window.STORAGE_ACCOUNT}/entraIdGuide-addMembers-step4.png`,
    ADDMEMBER_STEP5  : `${window.STORAGE_ACCOUNT}/entraIdGuide-addMembers-step5.png`,
    SYNCCONFIG_STEP1   : `${window.STORAGE_ACCOUNT}/entraIdGuide-syncUserConfiguration-step1.png`,
    SYNCCONFIG_STEP2_1 : `${window.STORAGE_ACCOUNT}/entraIdGuide-syncUserConfiguration-step2-1.png`,
    SYNCCONFIG_STEP2_2 : `${window.STORAGE_ACCOUNT}/entraIdGuide-syncUserConfiguration-step2-2.png`,
    SYNCCONFIG_STEP3   : `${window.STORAGE_ACCOUNT}/entraIdGuide-syncUserConfiguration-step3.png`,
    SYNCCONFIG_STEP4   : `${window.STORAGE_ACCOUNT}/entraIdGuide-syncUserConfiguration-step4.png`,
  },
  SESSION_TIMEOUT            : `${window.STORAGE_ACCOUNT}/session-timeout.svg`,
}

export default images;