import { Box, Button, Dialog, IconButton, Paper, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const EventExpirationModal = (props) => {
  const { open, handleSubmit, handleCancel } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      id="eventExpirationModal"
      data-testid="eventExpirationModal"
      modal="false"
      open={open}
      {...props}
      aria-labelledby="event-expiration-modal"
      className={classes.dialog}
    >
      <Paper id="eventExpirationModalMessageContainer" className={classes.dialogModal}>
        <Box className={classes.dialogTitle}>
          <Typography className={classes.title}>{t('settings-page.eventExpirationModalTitle')}</Typography>
          <IconButton onClick={handleCancel}>
            <Close fontSize='medium'/>
          </IconButton>
        </Box>
        <Box className={classes.dialogContent}>
          <Typography className={classes.message}>{t('settings-page.eventExpirationModalDescription')}</Typography> 
        </Box>
      </Paper>
      <Box id="eventExpirationModalButtonsContainer" className={classes.dialogActions}>
        <Button id="eventExpirationModalChangeButton" color="primary" className={classes.dialogButton} onClick={handleCancel} >
          {t('settings-page.eventExpirationModalCancelButton')}
        </Button>
        <Button id="eventExpirationModalSaveButton" color="primary" variant="contained" className={classes.dialogButton} onClick={handleSubmit} >
          {t('settings-page.eventExpirationModalSubmitButton')}
        </Button>
      </Box>
    </Dialog>
  );
}

export default EventExpirationModal;