import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ActionModal from '../../../components/controller-modal';
import { rebootController } from '../../../service/controllersApi';
import { API_REQUEST_ERROR_MESSAGE } from '../../../utility/constants';
import useStyles from './styles';
import { Box, Typography } from '@material-ui/core';

/**
 * @param {object} props - The `ControllerParametersModal` component accepts the following props:
 * @property {boolean} isOpen - Determines if the modal is visible.
 * @property {function} onClose - Callback function triggered when the modal is closed.
 * @property {number} controllerId - Username for the controller, displayed as a parameter.
 * @property {function} showToaster - Toaster function triggered after the API call.
 * @property {string} name - Controller name.
 * @returns {JSX.Element|null} The `ControllerParametersModal` component renders a modal displaying the controller's connection parameters,
 * including a QR code, URL, port, username, and password. If `isOpen` is false, the component renders `null`.
 */
const ControllerRestartModal = ({ 
  isOpen, 
  onClose, 
  controllerId, 
  showToaster,
  name,
  showLoading
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  if (!isOpen) return null;

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      await rebootController(controllerId);
  
      showToaster(t('success'), t('controller-modal.restart-controller-success', { name: values.name }), 'success');
      onClose();
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('controller-modal.restart-controller-title', { name })}
      cancelButtonLabel={t('controller-modal.cancel-button')}
      submitButtonLabel={t('controller-modal.restart-controller-submit-button', { name })}
      handleSubmit={handleSubmit}
    >
      { showLoading(isLoading) }
      <Box className={classes.content}>
        <Typography>
          <Trans i18nKey={'controller-modal.restart-controller-description'} values={{ name }}/>
        </Typography>
        <Typography className={classes.warning}>
          <Trans i18nKey={'controller-modal.restart-controller-confirmation-message'}/>
        </Typography>
      </Box>
    </ActionModal>
  );
};

ControllerRestartModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  controllerId: PropTypes.number.isRequired,
  showToaster: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  showLoading: PropTypes.func.isRequired,
};

export default ControllerRestartModal;