import { Container } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Table, { createColumn } from '../../components/table';
import Title from '../../components/title';
import { LocationContext } from '../../context/locationContext';
import { getControllers } from '../../service/controllersApi';
import { ALL, API_REQUEST_ERROR_MESSAGE, ASCENDING, CONTROLLER, CONTROLLERS, CONTROLLERS_MODULE, CPU, DASHBOARD, DATA_DISK, DEVICE_STATUS_ONLINE, NAME, OFFLINE, RAM, SYSTEM_DISK, WSTOPIC } from '../../utility/constants';
import { getControllerImage, parseIntegerParam, updateURLParams } from '../../utility/helper';
import ControllerCreateModal from './controller-create-modal';
import useStyles from './styles';

const Controllers = (props) => {
  const { t } = useTranslation();

  const columns = [
    createColumn("id", "ID", false, "numeric", true),
    createColumn("name", 'controller', true, "component", false, true),
    createColumn("deviceState", 'status', false, "boolean"),
    createColumn("location", 'location', true, "string"),
    createColumn("deviceVersion", 'osswVersion', false, "String"),
    createColumn("overallStatus", 'overallStateMisc', false, "String"),
  ];

  const { showToaster, handlePermissions, newMessage, setNewMessage, newTopic, setNewTopic }  = props;

  const classes = useStyles();
  const history = useHistory();

  const { state : locationState }   = useContext(LocationContext);
  const { selectedLocationIds }     = locationState;
  const { search, pathname, state } = history.location;

  const params      = new URLSearchParams(search);
  const query       = params.get('keyword') ? params.get('keyword') : '';
  const pageSize    = params.get('size') ? parseIntegerParam(params.get('size'), 10) : 10;
  const pageNumber  = params.get('page') ? parseIntegerParam(params.get('page'), 1) : 1;
  const pageType    = params.get('controllers') ? params.get('controllers') : ALL.toLowerCase();
  const totalSize   = pageSize > 100 ? 100 : pageSize;
  
  const [isLoading, setIsLoading]                           = useState(false);
  const [rowsPerPage, setRowsPerPage]                       = useState(totalSize);
  const [page, setPage]                                     = useState(pageNumber === 0 ? 1 : pageNumber);
  const [controllers, setControllers]                       = useState([]);
  const [totalControllers, setTotalControllers]             = useState(0);
  const [orderBy, setOrderBy]                               = useState(NAME);
  const [order, setOrder]                                   = useState(ASCENDING);
  const [keyword, setKeyword]                               = useState(query);
  const [ws, setWs]                                         = useState(true);
  const [noLoadSkeleton, setNoLoadSkeleton]                 = useState(true);
  const [title, setTitle]                                   = useState('');
  const [listType, setListType]                             = useState(pageType);

  const urlParams = useMemo(() => ({
    keywordParam :  keyword ? `&keyword=${keyword}` : '',
    entityParam  : `controllers=${listType}`,
    pageParam    : `&page=${page}`,
    sizeParam    : `&size=${rowsPerPage}`,
    history      : history,
    pathname     : pathname
  }), [keyword, listType, page, rowsPerPage, history, pathname]);

  const setResponseData = useCallback((response) => {
    const { data } = response;
    const { number, size, totalElements, totalPages } = data.page;
    
    const totalNumber = number > totalPages ? totalPages : number;

    const empty = '---';

    const newControllers = data.controllers.map(controller => {
      const { name, cpu, dataDisk, ram, systemDisk, deviceId, deviceType, osVersion, serialNumber, status, swVersion, controllerId, location, unsyncedDataCount, housing } = controller;

      const deviceVersion = { OS  : osVersion, SW  : swVersion};
      const overAllState  = [
        getControllerState(1, DATA_DISK, dataDisk),
        getControllerState(2, SYSTEM_DISK, systemDisk),
        getControllerState(3, RAM, ram),
        getControllerState(4, CPU, cpu)
      ];

      return ({
        id        : controllerId,
        controller: {
          name  : name ? name : empty,
          type  : deviceType ? deviceType : empty,
          id    : deviceId ? deviceId : empty,
          // If status is empty, the controller is not yet provisioned
          // In 2.2.0 we added a serial_number UNIQUE constraint and every time we create a controller. We use NEWID() as its temp serial_number
          serial: status.trim() ? serialNumber : empty ,
          image : getControllerImage(deviceType, housing)
        },
        deviceState : {
          status          : (status.includes(DEVICE_STATUS_ONLINE) ? true : false),
          unsyncCount     : unsyncedDataCount,
        },
        location     : location ? location.name : '-',
        deviceVersion: deviceVersion,
        overAllState : overAllState
      })
    });

    if (newControllers.length || number === 0) {
      setPage(totalNumber + 1);
    } else {
      setPage(totalNumber);
    }

    setRowsPerPage(size);
    setTotalControllers(totalElements);
    setControllers(newControllers);
  }, [])

  window.onbeforeunload = () => {
    history.push({from : ''})
  };

  const fetchData = useCallback(async () => {
    const params = {
      name         : keyword,
      deviceId     : keyword,
      deviceType   : keyword,
      serialNumber : keyword,
      size         : rowsPerPage,
      page         : page - 1,
      sort         : `${orderBy},${order}`,
      locationIds  : selectedLocationIds.toString(),
    }

    try {
      const response = await getControllers(params);
      setResponseData(response);
    } catch (error) {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      if (ws) {
        setIsLoading(false);
        setNoLoadSkeleton(true);
      }
    }
  }, [keyword, order, orderBy, page, rowsPerPage, selectedLocationIds, setResponseData, showToaster, t, ws])

  const getData = useCallback((topic) => {
    setIsLoading(!topic);
    if (listType === ALL.toLowerCase() && ws) {
      setTitle(CONTROLLERS)
      fetchData();
    } else {
      setListType(ALL.toLowerCase());
    }
  }, [fetchData, listType, ws])

  useEffect(() => {
    let delayDebounce;

    if (keyword) {
      setIsLoading(true);
      delayDebounce = setTimeout(() => {
        updateURLParams(urlParams);
        getData();
      }, 1000);
    } else {
      updateURLParams(urlParams);
      getData();
    }

    return () => {
      delayDebounce && clearTimeout(delayDebounce)
    }
  }, [page, rowsPerPage, order, orderBy, keyword, ws, listType, selectedLocationIds, urlParams, getData]);

  const handleSearch = (value) => {
    setPage(1);
    setKeyword(value);
  }

  const handleClearSearch = () => {
    setPage(1);
    setKeyword('');
  }

  const handleSort = (newOrderBy, newOrder) => {
    setPage(1);
    setOrderBy(newOrderBy);
    setOrder(newOrder);
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setPage(1);
    setRowsPerPage(newRowsPerPage);
  }

  const handleChangePage = (newPage) => {
    if (isLoading) {
      return;
    }

    setPage(newPage + 1);
  }

  const handleUpdate = (value) => {
    history.push(`/controllers/update/${value.id}`);
  }

  const handleView = (row) => {
    history.push(`/controllers/view/${row.id}`);
  }

  const handleMultipleDelete = (_values) => {
    console.log('handleMDelete()');
  }

  const handleUpdateDevice = (row) => {
    console.log("handling update device...", row)
    showToaster(t('success'), `${t('underDevelopment')}`, 'success');
  }
  const formatOverallMiscData = useCallback((message) => {
    const unsyncCounted = 0;
    const { controllerOverallStateDTO } = message;

    if (state?.from === DASHBOARD) {
      return controllers.filter(item => item.controller.serial !== message.serialNumber);
    }

    return controllers.map(controller => {
      if (controller.controller.serial === controllerOverallStateDTO?.serialNumber) {
        return {
          ...controller,
          deviceState  : {
            status : (controllerOverallStateDTO.status === DEVICE_STATUS_ONLINE),
            unsyncCount: unsyncCounted,
          },
          overAllState: [
            getControllerState(1, DATA_DISK, controllerOverallStateDTO.dataDisk),
            getControllerState(2, SYSTEM_DISK, controllerOverallStateDTO.systemDisk),
            getControllerState(3, RAM, controllerOverallStateDTO.ram),
            getControllerState(4, CPU, controllerOverallStateDTO.cpu),
          ]
        }
      }

      return controller;
      
    });
  }, [controllers, state])

  const formatOverallStateData = useCallback((message) => {
    const unsyncCounted = 0;
    const { controllerOverallStateDTO } = message;

    return controllers.map(controller => {
      if (controller.controller.serial === controllerOverallStateDTO?.serialNumber) {
        return {
          ...controller,
          deviceState  : {
            status : (controllerOverallStateDTO.status === DEVICE_STATUS_ONLINE),
            unsyncCount: unsyncCounted,
          },
          deviceVersion: {
            OS  : controllerOverallStateDTO.osVersion,
            SW  : controllerOverallStateDTO.swVersion,
          },
          overAllState: [
            getControllerState(1, DATA_DISK, controllerOverallStateDTO.dataDisk),
            getControllerState(2, SYSTEM_DISK, controllerOverallStateDTO.systemDisk),
            getControllerState(3, RAM, controllerOverallStateDTO.ram),
            getControllerState(4, CPU, controllerOverallStateDTO.cpu)
          ]
        }
      }
      return controller;
      
    });
  }, [controllers])

  const formatWSStatusData = useCallback((message) => {
    const unsyncCounted = 0;

    return controllers.map(controller => {
      if (controller.controller.serial === message.controllerStatusDTO?.serialNumber) {
        return {
          ...controller,
          deviceState  : {
            status : (message.controllerStatusDTO.status === OFFLINE) ? false : true,
            unsyncCount: unsyncCounted,
          },
        }
      }
      
      return controller;
      
    });
  }, [controllers])

  const handleWSMessage = useCallback( () => {
    
    switch (newTopic) {
      case WSTOPIC.OVERALL_MISC: {
        const updatedControllers = formatOverallMiscData(newMessage);
        setControllers(updatedControllers);
        break;
      }
      case WSTOPIC.OVERALL_STATE: {
        const updatedControllers = formatOverallStateData(newMessage);
        setControllers(updatedControllers);
        break;
      }
      case WSTOPIC.CONTROLLER_STATUS: {
        const updatedControllers = formatWSStatusData(newMessage);
        setControllers(updatedControllers);
        break;
      }
      case WSTOPIC.NEW_CONTROLLER: {
        getData(newTopic);
        break;
      }
      case WSTOPIC.ERROR: {
        console.log("error", newMessage + newTopic);
        break;
      }
      default: {
        setNewMessage({});
        setNewTopic('');
        return;
      }
    }
    setNewMessage({});
    setNewTopic('');

  }, [formatOverallMiscData, formatOverallStateData, formatWSStatusData, getData, newMessage, setNewMessage, newTopic, setNewTopic])

  const handleType = () => {
    setListType(ALL.toLowerCase());
  }

  const getControllerState = (index, name, value) => {
    return {
      id    : index,
      name  : name,
      value : value
    }
  }

  useEffect(() => {
    if (newTopic !== '' && (Object.keys(newMessage).length > 0 && newMessage.constructor === Object)) {
      setWs(false);
      handleWSMessage();
      setWs(true);
    }
  }, [handleWSMessage, newTopic, newMessage]);


  const [showModal, setShowModal] = useState(false);

  const handleOpenShowModal = () => {
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Title title={t(title)} listType={listType}/>
      <ControllerCreateModal
        isOpen={showModal}
        onClose={handleCloseModal}
        showToaster={showToaster}
      />
      <Table
        columns={columns}
        data={controllers}
        isLoading={isLoading&&noLoadSkeleton}
        keyword={keyword}
        label={CONTROLLER}
        module={CONTROLLERS_MODULE}
        onChangePage={handleChangePage}
        onClearSearch={handleClearSearch}
        onCreate={handleOpenShowModal}
        onMultipleDelete={handleMultipleDelete}
        onRowsPerPageChange={handleRowsPerPageChange}
        onSearch={handleSearch}
        onSort={handleSort}
        onUpdate={handleUpdate}
        onUpdateDevice={handleUpdateDevice}
        onView={handleView}
        order={order}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        totalItems={totalControllers}
        viewKey={"controller"}
        listType={listType}
        onViewAll={handleType}
        handlePermissions={handlePermissions}
      />
    </Container>
  );
}

export default Controllers;