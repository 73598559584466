import { Box, Switch, Tooltip } from '@material-ui/core'
import React, { useState } from 'react'
import Chip from '../../../components/chip'
import BadgeIcon from '@mui/icons-material/Badge';
import { ACTION_VIEW, CHIP_COMPONENT, CHIP_ICON, CREDENTIALS } from '../../../utility/constants';
import { CopyAllOutlined } from '@mui/icons-material';
import useStyles from './styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const UserCredentialChip = (props) => {
  const { userDetailContainerWidth, credential } = props;
  const [isClicked, setIsClicked] = useState(false);
  
  const { t } = useTranslation();

  const classes = useStyles();

  const handleClickCredential = (event) => {
    const { id } = event.target.parentNode;
    if (id === CHIP_ICON) {
      setIsClicked(true);
      navigator.clipboard.writeText(credential?.name);
      return;
    }

    window.open(`/${CREDENTIALS}/${ACTION_VIEW}/${credential.id}`, '_blank')
  }

  const handleMouseLeave = () => {
    setIsClicked(false);
  }

  return (
    <Box className={
      clsx(
        userDetailContainerWidth >= 1720 ? classes.longComplexChip : classes.shortComplexChip
      )}
    > 
      <Chip
        id={`user-credential-chip${credential.credentialId}`}
        recordId={credential.credentialId}
        icon={<BadgeIcon fontSize='small' />}
        title={credential?.name}
        description={credential?.description}
        isFor={CHIP_COMPONENT.CREDENTIAL}
        fullWidth
        endComponent={
          <>
            <Tooltip 
              title={isClicked ? t('user-page.copied') : t('user-page.copy')} 
              className={classes.iconTooltip}
            >
              <Box id="chipIcons" 
                onMouseLeave={handleMouseLeave}
                className={clsx(classes.copyIconContainer)}>
                <CopyAllOutlined />
              </Box>
            </Tooltip>
            <Switch
              className={classes.switchBase}
              id={`${credential.credentialId}CredentialActiveSwitch`}
              disabled
              defaultChecked={credential?.endContent}
              size="small"
            />
          </>
        }
        onClick={handleClickCredential}
      />
    </Box>
  )
}

export default UserCredentialChip;