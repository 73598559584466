import React, { useEffect, useRef, useState } from 'react';
import { Box, Chip as MuiChip, Tooltip, Typography } from '@material-ui/core';
import { Cancel as CancelIcon } from '@material-ui/icons';
import clsx from 'clsx';
import useStyles from './styles';
import { CHIP_COLOR, CHIP_COMPONENT } from '../../utility/constants';

const Chip = (props) => {
  const classes = useStyles();
  const { icon, title, description, onClick, onDelete, startComponent, endComponent, color, fullWidth = false, isFor = CHIP_COMPONENT.COMMON, id, recordId, firmware } = props;
  const textRef = useRef(null);

  const [isOverflowed, setIsOverflowed] = useState(false);

  const handleClick = (e) => {
    onClick(e);
  }

  const handleDelete = () => {
    onDelete();
  }

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        setIsOverflowed(
          textRef.current.scrollWidth > textRef.current.clientWidth
        );
      }
    };

    checkOverflow();
  }, []);

  return (
    <MuiChip
      id={`${id}${recordId}Chip`}
      icon={icon}
      className={
        clsx(
          classes.chip,
          {
            [classes.userChips]      : isFor === CHIP_COMPONENT.CREDENTIAL || isFor === CHIP_COMPONENT.PROFILE,
            [classes.userRoleChips]  : isFor === CHIP_COMPONENT.ROLE,
            [classes.readerChip]     : isFor === CHIP_COMPONENT.READER,
            [classes.accessPointChip]: isFor === CHIP_COMPONENT.ACCESS_POINT,
            [classes.fullWidth]      : fullWidth,
            [classes.light]          : color === CHIP_COLOR.LIGHT,
            [classes.neutral]        : color === CHIP_COLOR.NEUTRAL,
            [classes.offline]        : color === CHIP_COLOR.OFFLINE,
            [classes.online]         : color === CHIP_COLOR.ONLINE,
            [classes.outline]        : color === CHIP_COLOR.OUTLINE,
            [classes.plain]          : color === CHIP_COLOR.PLAIN,
          } 
        )
      }
      size={isFor === CHIP_COMPONENT.ACCESS_POINT ? "medium" : "small"}
      color={"primary"}
      label={
        <div className={isFor !== CHIP_COMPONENT.SYNC && classes.labelContainer}>
          <Box>
            { startComponent }
          </Box>
          <Tooltip classes={{ tooltip: classes.customTooltip }} title={title} disableHoverListener={!isOverflowed}>
            <Box className={clsx({
                  [classes.label]    : isFor !== CHIP_COMPONENT.SYNC,
                  [classes.minLabel] : isFor !== CHIP_COMPONENT.READER,
                })
              }>
              <Box className={clsx({[classes.userCredentialChips]: isFor === CHIP_COMPONENT.CREDENTIAL})}>
                  <Typography innerRef={textRef} noWrap display="block" variant="caption">
                    <span className={clsx({
                      'bold'                : isFor !== CHIP_COMPONENT.ACCESS_POINT,
                      [classes.title]       : isFor === CHIP_COMPONENT.ACCESS_POINT,
                      [classes.readerTitle] : isFor === CHIP_COMPONENT.READER,
                    })} 
                    id={`${id}${recordId}ChipTitle`}
                  >
                    {title}
                  </span>
                  </Typography>
                <Typography display="block" variant="caption" className={isFor === CHIP_COMPONENT.READER && classes.description}>
                  <span id={`${id}${recordId}ChipFirmware`}>{firmware}</span>
                </Typography>
                <Typography display="block" variant="caption" className={isFor === CHIP_COMPONENT.READER && classes.description}>
                  <span id={`${id}${recordId}ChipDescription`}>{description}</span>
                </Typography>
              </Box>
              <Box className={classes.endContent}>
                { endComponent }
              </Box>
            </Box>
          </Tooltip>
        </div>
      }
      deleteIcon={
        (onDelete) ?
            <CancelIcon/>
          :
            <></>
      }
      onDelete={handleDelete}
      onClick={handleClick}
    />
  )
}

export default Chip;