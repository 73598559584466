import { Box, Button, Container, Divider, Grid, LinearProgress, Link, Paper, Typography } from '@material-ui/core';
import { DateRange } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EnhancedTitle from '../../components/enhanced-title';
import { getControllerById } from '../../service/controllersApi';
import { API_REQUEST_ERROR_MESSAGE, CONTROLLER_DEVICE_TYPE, CONTROLLER_HOUSING, CPU, DATA_DISK, EVENTS, OFFLINE, ONLINE, RAM, READ_MODE, SYSTEM_DISK, WSTOPIC } from '../../utility/constants';
import { getControllerImage } from '../../utility/helper';
import { GetInitialLocationObject } from '../../utility/location';
import AccessPointsChips from './controller-access-points-chips';
import ControllerParametersModal from './controller-parameters-modal';
import ControllerRebuildModal from './controller-rebuild-modal';
import ControllerRemoveModal from './controller-remove-modal';
import ControllerRestartModal from './controller-restart-modal';
import ControllerSkeleton from './controller-skeleton';
import ControllerUpdateFirmwareModal from './controller-update-firmware-modal';
import ControllerUpdateModal from './controller-update-modal';
import useStyles from './styles';

const Controller = (props) => {
  const { showToaster, showLoading, match, handlePermissions, newTopic, setNewTopic, newMessage, setNewMessage } = props;

  const classes   = useStyles();
  const { id }    = match.params;
  const { t }     = useTranslation();

  const initialLocationObject = GetInitialLocationObject();

  const [isLoading, setIsLoading]                     = useState(false);
  const [name, setName]                         = useState('');
  const [deviceType, setDeviceType]             = useState('---');
  const [deviceId, setDeviceId]                 = useState('---');
  const [serialNumber, setSerialNumber]                 = useState('---');
  const [osVersion, setOsVersion]               = useState('---');
  const [swVersion, setSwVersion]               = useState('---');
  const [status, setStatus]                     = useState('---');
  const [housing, setHousing]                   = useState('');
  const [overAllState, setOverAllState]               = useState([]);
  const [accessPoints, setAccessPoints]               = useState([]);
  const [readersCount, setReadersCount]               = useState(0);
  const [isShowChameleon, setIsShowChameleon]         = useState(false);
  const [location, setLocation]                       = useState('');
  const [locationObject, setLocationObject]           = useState(initialLocationObject);

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isRebuildModalOpen, setIsRebuilModalOpen] = useState(false);
  const [isRestartModalOpen, setIsRestartModalOpen] = useState(false);
  const [isFirmwareModalOpen, setIsFirmwareModalOpen] = useState(false);
  const [isParametersModalOpen, setIsParametersModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  
  // Controller parameters
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const isControllerOnboarded = deviceId !== '---';
  const titleProps = {
    title       : name,
    subtitle    : name,
    actionButton: [<></>], 
    isLoading
  }

  const getAccessPointsData = useCallback((values) => {
    return values.map(accessPoint => {
      const { accessPointId, doorMode, name, readers } = accessPoint;

      let entries = null;

      if (readers != null) {
        entries = (
          readers.map(reader => {
            return ({
              name : reader.name,
              description: t(READ_MODE[reader.readMode]) ?? '',
              firmware: reader.firmware ?? ''
            })
          })
        );
      }

      return {
        id        : accessPointId,
        name      : name,
        endContent: doorMode,
        details   : entries
      }
    });
  }, [t])

  const getController = useCallback(async () => {
    setIsLoading(true);
    try {

      const response = await getControllerById(id);

      const { deviceId, deviceType, osVersion, serialNumber, status, swVersion, name, username, password, dataDisk, systemDisk, ram, cpu, accessPoints, housing, location } = response;
      
      const overAllStateList = [
        getControllerState(1, DATA_DISK, dataDisk),
        getControllerState(2, SYSTEM_DISK, systemDisk),
        getControllerState(3, RAM, ram),
        getControllerState(4, CPU, cpu)
      ];

      setName(name);
      setUsername(username);
      setPassword(password);
      setOverAllState(overAllStateList);
      setLocation(location.name);
      setLocationObject([{
        locationId  : parseInt(location.location_id),
        name        : location.name
      }]);

      // Check if controller is onboarded with the presence of status
      // If status is empty, the controller is not yet provisioned
      // In 2.2.0 we added a serial_number UNIQUE constraint and every time we create a controller. We use NEWID() as its temp serial_number
      if (status.trim()) {
        setStatus(status);
        setDeviceType(deviceType);
        setDeviceId(deviceId);
        setSerialNumber(serialNumber);
        setOsVersion(osVersion);
        setSwVersion(swVersion);
        setHousing(housing);
      }

      if (accessPoints?.length) {
        const accessPointData = getAccessPointsData(accessPoints);
        setAccessPoints(accessPointData);

        const totalReaders = accessPoints.reduce((sum, accessPoint) => sum + accessPoint.readers.length, 0);
        setReadersCount(totalReaders)

      }
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
      setIsShowChameleon(false);
    }
  }, [id, showToaster, t, getAccessPointsData]);

  useEffect(() => {
    getController();
  }, [getController]);

  const getControllerState = (index, name, value) => {
    return {
      id    : index,
      name  : name,
      value : value
    }
  }

  const handleWSMessage = useCallback(() => {
    const { controllerOverallStateDTO, controllerStatusDTO, newControllerDTO } = newMessage;
    const topic = newTopic;
    setNewMessage({});
    setNewTopic('');

    switch (topic) {
      case WSTOPIC.OVERALL_MISC: {
        if (serialNumber === controllerOverallStateDTO?.serialNumber) {
          const updatedOverallState = [
            getControllerState(1, 'dataDisk', controllerOverallStateDTO.dataDisk),
            getControllerState(2, 'systemDisk', controllerOverallStateDTO.systemDisk),
            getControllerState(3, 'Ram', controllerOverallStateDTO.ram),
            getControllerState(4, 'CPU', controllerOverallStateDTO.cpu),
          ];

          setOverAllState(updatedOverallState);
        }
        return
      }
      case WSTOPIC.OVERALL_STATE: {
        if (serialNumber === controllerOverallStateDTO?.serialNumber) {
          const updatedOverallState = [
            getControllerState(1, 'dataDisk', controllerOverallStateDTO.dataDisk),
            getControllerState(2, 'systemDisk', controllerOverallStateDTO.systemDisk),
            getControllerState(3, 'Ram', controllerOverallStateDTO.ram),
            getControllerState(4, 'CPU', controllerOverallStateDTO.cpu),
          ];

          setOverAllState(updatedOverallState);
          setOsVersion(controllerOverallStateDTO.osVersion);
          setSwVersion(controllerOverallStateDTO.swVersion);
        }
        return
      }
      case WSTOPIC.CONTROLLER_STATUS: {
        if (serialNumber === controllerStatusDTO?.serialNumber) {
          setStatus(controllerStatusDTO.status);
        }
        return
      }
      case WSTOPIC.NEW_CONTROLLER: {
        if (id === newControllerDTO.controllerId) {
          const updatedOverallState = [
            getControllerState(1, 'dataDisk', newControllerDTO.dataDisk),
            getControllerState(2, 'systemDisk', newControllerDTO.systemDisk),
            getControllerState(3, 'Ram', newControllerDTO.ram),
            getControllerState(4, 'CPU', newControllerDTO.cpu),
          ];
          
          setDeviceType(newControllerDTO.deviceType);
          setDeviceId(newControllerDTO.deviceId);
          setSerialNumber(newControllerDTO.serialNumber);
          setOsVersion(newControllerDTO.osVersion);
          setSwVersion(newControllerDTO.swVersion);
          setStatus(newControllerDTO.status)
          setOverAllState(updatedOverallState);
        }
        return;
      }
      case WSTOPIC.NEW_ACCESSPOINT: {
        if (id === newControllerDTO.controllerId) {
          const accessPointData = getAccessPointsData(newControllerDTO?.accessPoints);
          setAccessPoints(accessPointData);
        }
        return;
      }
      case WSTOPIC.ERROR: {
        return null;
      }
      default: return null;
    }
  }, [serialNumber, id, newMessage, setNewMessage, newTopic, setNewTopic, getAccessPointsData])

  useEffect(() => {
    if (newTopic !== '' && (Object.keys(newMessage).length > 0 && newMessage.constructor === Object)) {
      handleWSMessage();
    }
  }, [newTopic, newMessage, handleWSMessage]);

  const getChip = (status) => {
    if (status === ONLINE) {
      return <span variant="h6" className={classes.onlineText}>{t(`controller-page.online`)}</span>
    } else if (status === OFFLINE) {
      return <span variant="h6" className={classes.offlineText}>{t(`controller-page.offline`)}</span>
    } else {
      return status;
    }
  }

  const showEvents = () => {
    window.open(`/${EVENTS}?deviceIds=0:${deviceId}`, '_blank') 
  }

  const showControllerParametersModal = () => {
    setIsParametersModalOpen(true);
  }

  const showUpdateFirmwareModal = () => {
    setIsFirmwareModalOpen(true);
  }

  const showRestartControllerModal = () => {
    setIsRestartModalOpen(true);
  }

  const showRebuildModal = () => {
    setIsRebuilModalOpen(true);
  }

  const showUpdateModal = () => {
    setIsUpdateModalOpen(true);
  }

  const closeUpdateModal = async(updatedValues) => {
    if (updatedValues.name) {
      setName(updatedValues.name);
      setLocationObject(updatedValues.location);
      setLocation(updatedValues.location[0].name)
    }

    setIsUpdateModalOpen(false);
  }

  const showRemoveModal = () => {
    setIsRemoveModalOpen(true);
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      {showLoading(isShowChameleon)}
      <Box className={classes.contentHeader}>
        <EnhancedTitle {...titleProps} />
        {
          isControllerOnboarded &&
            <Button
              color="primary"
              variant="contained"
              size="medium"
              className={classes.showEventButton}
              onClick={showEvents}
            >
              <DateRange fontSize="small"/>
              &nbsp;&nbsp;
              {t(`controller-page.showEvents`)}
            </Button>
        }
      </Box>
      <ControllerRemoveModal 
        controllerId={id}
        isOpen={isRemoveModalOpen}
        showToaster={showToaster}
        onClose={() => setIsRemoveModalOpen(false)}
        name={name}
        showLoading={showLoading}
      />
      <ControllerRebuildModal 
        controllerId={id}
        isOpen={isRebuildModalOpen}
        showToaster={showToaster}
        onClose={() => setIsRebuilModalOpen(false)}
        name={name}
        showLoading={showLoading}
      />
      <ControllerRestartModal 
        controllerId={id}
        isOpen={isRestartModalOpen}
        showToaster={showToaster}
        onClose={() => setIsRestartModalOpen(false)}
        name={name}
        showLoading={showLoading}
      />
      <ControllerUpdateFirmwareModal 
        isOpen={isFirmwareModalOpen}
        onClose={() => setIsFirmwareModalOpen(false)}
        controllerId={id}
        name={name}
        showToaster={showToaster}
        showLoading={showLoading}
      />
      <ControllerParametersModal 
        isOpen={isParametersModalOpen}
        onClose={() => setIsParametersModalOpen(false)}
        username={username}
        password={password}
      />
      <ControllerUpdateModal 
        isOpen={isUpdateModalOpen}
        onClose={closeUpdateModal}
        name={name}
        selectedLocation={locationObject}
        showToaster={showToaster}
        controllerId={id}
        handlePermissions={handlePermissions}
        showLoading={showLoading}
      />
      <Box className={classes.details}>
        <Paper data-testid="controllerContainer" className={classes.mainPaper} elevation={3}>
          {
            isLoading ? 
              <ControllerSkeleton/>
            : isControllerOnboarded ?
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                  <img
                    src={getControllerImage(deviceType, housing)}
                    alt='controller'
                    className={housing === CONTROLLER_HOUSING.NINETEENINCHRACK ? classes.rectangularImage : classes.squareImage}
                  />
                  <Typography variant="h6" className={classes.textTitle}>{t(`actions`)}</Typography>
                  <Link variant="body1" className={classes.link} onClick={() => showControllerParametersModal()}>{t(`controller-page.getControllerParameters`)}</Link>
                  <Link variant="body1" className={status === ONLINE ? classes.link : classes.hidden} onClick={() => showUpdateFirmwareModal()}>{t(`controller-page.updateFirmware`)}</Link>
                  <Link variant="body1" className={status === ONLINE ? classes.link : classes.hidden} onClick={() => showRestartControllerModal()}>{t(`controller-page.restartController`)}</Link>
                  <Link variant="body1" className={status === ONLINE ? classes.link : classes.hidden} onClick={() => showRebuildModal()}>{t(`controller-page.rebuild`)}</Link>
                  <Link variant="body1" className={classes.link} onClick={() => showUpdateModal()}>{t(`controller-page.changeNameAndOrLocation`)}</Link>
                  <Link variant="body1" color="error" className={classes.link} onClick={() => showRemoveModal()}>{t(`controller-page.removeController`)}</Link>
                </Grid>
                <Grid item xs={12} sm={6} md={8} lg={3} xl={2}>
                  <Typography variant="h6" className={classes.textTitle}>
                    {`${t(`controller-page.currentStatus`)}: `}{getChip(status)}
                  </Typography>
                  <Box className={classes.properties}>
                    <Typography>
                      {`${t(`controller-page.name`)}: ${name}`}
                    </Typography>
                    <Typography>
                      {`${t(`controller-page.location`)}: ${location}`}
                    </Typography>
                    <Typography>
                      {`${t(`controller-page.deviceType`)}: ${deviceType}`}
                    </Typography>
                    <Typography>
                      {`${t(`controller-page.deviceId`)}: ${deviceId}`}
                    </Typography>
                    <Typography>
                      {`${t(`controller-page.serialNumber`)}: ${serialNumber}`}
                    </Typography>
                    <Typography>
                      {`${t(`controller-page.osVersion`)}: ${osVersion}`}
                    </Typography>
                    <Typography>
                      {`${t(`controller-page.swVersion`)}: ${swVersion}`}
                    </Typography>
                  </Box>
                  <Divider className={classes.divider}></Divider>
                  <Typography variant="h6" className={classes.textTitle}>
                    {t(`controller-page.workingStatistics`)}
                  </Typography>
                  {
                    overAllState.map((state) => {
                      const { id, name, value } = state;

                      return (
                        <Box className={deviceType === CONTROLLER_DEVICE_TYPE.SECPASS && name === SYSTEM_DISK ? classes.hidden : classes.statistics} key={`statistics-${id}`}>
                          <Typography>{`${t(name)}: `}<span className={classes.stateValue}>{Math.round(value,)}%</span></Typography>
                          <LinearProgress variant="determinate" value={value} />
                        </Box>
                      )
                    })
                  }
                </Grid>
                <Grid component={Box} item md={4} className={classes.fakeGrid} sx={{display: { xs: "none", sm: "none", md: "block", lg: "none", xl: "none" }}}></Grid>
                <Grid item xs={12} sm={12} md={8} lg={6} xl={3}
                >
                  <AccessPointsChips
                    data={accessPoints}
                    accessPointsCount={accessPoints.length}
                    readersCount={readersCount}
                  />
                </Grid>
              </Grid>
            : 
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">{t(`controller-page.newControllerLabel`)}</Typography>
                  <Button
                    color="primary"
                    variant="contained"
                    size='medium'
                    className={classes.button}
                    onClick={showControllerParametersModal}
                  >
                    {t(`controller-page.getControllerParameters`)}
                  </Button>
                  <Grid item xs={12} sm={6} md={3}>
                    <Divider className={classes.newControllerDivider}></Divider>
                  </Grid>
                  <Link variant="body1" className={classes.link} onClick={() => showUpdateModal()}>{t(`controller-page.changeNameAndOrLocation`)}</Link>
                  <Link variant="body1" color="error" className={classes.link} onClick={() => showRemoveModal()}>{t(`controller-page.removeController`)}</Link>
                </Grid>
              </Grid>
          }
        </Paper>
      </Box>
    </Container>
  );
}

export default Controller;