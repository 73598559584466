import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  copyIconContainer: {
    textAlign: 'center',
    lineHeight: '1px',
    width: 'fit-content !important',
    '& .MuiSvgIcon-root': {
      color: colors.CHIP_ICON_PLAIN
    }
  },
  iconTooltip: {

  },
  longComplexChip: {
    minWidth: theme.spacing(50),
    maxWidth: theme.spacing(50)
  },
  shortComplexChip: {
    minWidth: theme.spacing(37.5),
    maxWidth: theme.spacing(37.5)
  },
  switchBase: {
    width  : 52, 
    height : 32,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin : 4,
      '&.Mui-checked': {
        transform: 'translateX(20px)',
        color    : '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#1E1E1F1F',
          opacity        : 1,
          border         : 0,
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: '#fff',
          position       : 'relative',
          width          : 24, 
          height         : 24, 
          '&::before': {
            content        : '""',
            position       : 'absolute',
            top            : '50%',
            left           : '50%',
            transform      : 'translate(-50%, -50%)',
            width          : theme.spacing(2),
            height         : theme.spacing(2),
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%231E1E1F80" viewBox="0 0 24 24"><path d="M9 16.2l-3.5-3.5L4 14.2 9 19.2l11-11L18.6 7l-9.6 9.6z"/></svg>')`, // Custom check SVG icon
            backgroundSize : 'cover',
          },
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&:not(.Mui-checked)': {
        '& .MuiSwitch-thumb': {
          backgroundColor: '#1E1E1F80',
          position       : 'relative',
          width          : 24,
          height         : 24, 
          '&::before': {
            content        : '""',
            position       : 'absolute',
            top            : '50%',
            left           : '50%',
            transform      : 'translate(-50%, -50%)',
            width          : theme.spacing(2),
            height         : theme.spacing(2),
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23FFFFFF80" viewBox="0 0 24 24"><path d="M18.3 5.71L12 12l6.3 6.29-1.42 1.42L12 13.42l-6.29 6.29-1.42-1.42L10.59 12 4.29 5.71 5.71 4.29 12 10.59l6.29-6.3z"/></svg>')`, // Custom X SVG icon
            backgroundSize : 'cover', 
          },
        },
        '& + .MuiSwitch-track': {
          backgroundColor: '#1E1E1F1F',
          border         : '2px solid #1E1E1F80',
        },
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: '#ccc', 
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing      : 'border-box',
      width          : 24,
      height         : 24, 
      backgroundColor: '#1E1E1F80',
      boxShadow      : 'none', 
    },
    '& .MuiSwitch-track': {
      borderRadius   : 100, 
      backgroundColor: '#E9E9EA',
      opacity        : 1,
    },
  },
}));