import { Box, Button, IconButton, Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, MeetingRoom, Wifi } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ACCESS_POINTS, ACTION_VIEW } from '../../../utility/constants';
import useStyles from './styles';

const AccessPointsChip = (props) => {
  const { id, name, details, showAllTrigger } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const toggleDetails = () => {
    setIsOpen(prev => !prev)
  }

  useEffect(() => {
    if (showAllTrigger) {
      setIsOpen(true);
    }
  }, [showAllTrigger])

  return (
    <Box className={classes.accessPointContainer}>
      <Box className={classes.accessPointTextContainer}>
        <Typography className={classes.accessPointText}><MeetingRoom fontSize="medium" color="textPrimary" /> {name}</Typography>
        <IconButton onClick={toggleDetails} className={classes.collapseButton}>
          {
            isOpen ? <ArrowDropUp fontSize='medium' /> : <ArrowDropDown fontSize='medium' />
          }
        </IconButton>
      </Box>
      {
        isOpen &&
        <Box className={classes.readersContainer}>
          {
            details.map((reader) => {
              const { name, description, firmware } = reader;

              return (
                <Box className={classes.readerContainer}>
                  <Wifi fontSize="medium" color="textPrimary" />
                  <Box>
                    <Typography ><strong>{name}</strong></Typography>
                    <Typography>{firmware}</Typography>
                    <Typography>{description}</Typography>
                  </Box>
                </Box>
              )
            })
          }
          <Button
            color="primary"
            variant="text"
            size='medium'
            className={classes.accessPointButton}
            onClick={() => window.open(`/${ACCESS_POINTS}/${ACTION_VIEW}/${id}`, '_blank')}
          >
            {t(`controller-page.goToAccessPointsPage`)}
          </Button>
        </Box>
      }
    </Box>
  )
}

const AccessPointsChips = (props) => {
  const { data, accessPointsCount, readersCount } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const [showTrigger, setShowTrigger] = useState(0);

  const showAll = () => {
    setShowTrigger(prev => prev + 1);
  }

  return (
    <>
      <Typography className={classes.title}>{`${accessPointsCount}x`}&nbsp;<MeetingRoom fontSize="small" color="textPrimary" />&nbsp;{t(`access-points`)}&nbsp;&nbsp;&nbsp;&nbsp;{`${readersCount}x`}&nbsp;<Wifi fontSize="small" color="textPrimary" />&nbsp;{t(`readers`)}</Typography>
      <Box className={classes.accessPointsContainer}>
        {
          data.map((accessPoint) => {
            const { id, name, details } = accessPoint;

            return (
              <AccessPointsChip id={id} name={name} details={details} showAllTrigger={showTrigger} />
            )
          })
        }
      </Box>
      <Button
        color="primary"
        variant="text"
        size='medium'
        className={classes.expandButton}
        onClick={showAll}
      >
        {t(`controller-page.expandAll`)}
      </Button>
    </>
  )
}

export default AccessPointsChips;