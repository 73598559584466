import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  title: {
    display: 'flex',
    fontWeight: 'bold',
  },
  accessPointsContainer: {
    marginBlock: theme.spacing(2)
  },
  accessPointContainer: {
    backgroundColor: colors.SESAMSEC_SYS_LIGHT_SURFACE_CONTAINER_LOWEST,
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  accessPointTextContainer: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1)
  },
  accessPointText: {
    alignItems: 'center',
    display: 'flex',
  },
  collapseButton: {
    color: colors.PRIMARY_TEXT,
    padding: 0,
  },
  readersContainer: {
    flexDirection: 'column',
    display: 'flex',
  },
  readerContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginInline: theme.spacing(2),

    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(2)
    }
  },
  accessPointButton: {
    alignSelf: 'end',
    margin: theme.spacing(2),
    width: 'fit-content',
    padding: theme.spacing(0),
    lineHeight: '0.875rem',
    '& .MuiButton-root:hover': {
      backgroundColor: 'none'
    }
  },
  expandButton: {
    width: '100%'
  },
}));