const colors = {
  // THEME
  PRIMARY         : '#006AB3',
  PRIMARY_LIGHT   : '#006AB319',
  PRIMARY_DARK    : '#055C98',
  SECONDARY       : '#707173',
  SECONDARY_LIGHT : '#70717319',
  SECONDARY_DARK  : '#707173',
  WHITE           : '#fff',
  GRAY            : '#7071734D',
  LIGHT_GRAY      : '#F1F1F1',
  DARK_GRAY       : '#1E1E1F',
  BLACK           : '#000',
  BLUE            : '#D6EAF8',

  // TEXT
  PRIMARY_TEXT    : '#1E1E1F',
  SECONDARY_TEXT  : '#707173',
  LIGHT_GRAY_TEXT : '#838486',
  DARK_GRAY_TEXT  : '#5B5B5B',
  
  // ICON
  DARK_GRAY_ICON  : '#5B5B5B',
  LIGHT_GRAY_ICON : '#EAEBEB',

  DISABLED_TEXT   : 'gray',

  //SCHEDULE TABLE
  TIME_TABLE      : '#2FB56A54',

  // STATUS
  ERROR           : '#972B21',
  WARNING         : 'orange',
  SUCCESS         : '#269255',
  SUCCESS_DARK    : '#256625',
  NEUTRAL         : 'gray',
  IN_PROGRESS     : '#B27B2A',

  CHART_LINE_COLOR  : '#f86c6c',
  CHART_BLUE        : '#004775',
  CHART_GREEN       : '#1E4B48',
  CHART_YELLOW      : '#F7B93E',
  CHART_BLUE_LIGHT : '#4CD3B3',
  CHART_RED_LIGHT   : '#E23759',
  CHART_ORANGE      : '#F5523F',

  CHART_ORANGE_LIGHT: '#F0631C',
  CHART_GREEN_LIGHT : '#23A41D',
  CHART_INDIGO      : '#1D23A4',
  CHART_OLIVE       : '#1DA49E',

  CHART_BORDER_BLUE : '#006AB3',
  CHART_BG_BLUE     : '#006AB31A',

  // CHIPS
  CHIP_BACKGROUND_BLUE: '#E5F0F7',
  CHIP_TEXT_BLUE      : '#006AB3',
  CHIP_PLAIN          : '#5B5B5B',
  CHIP_ICON_PLAIN     : '#838486',

  COMPONENT_STATE_HOVER : '#F5F5F5',

  SESAMSEC_SYS_LIGHT_OUTLINE_SILENT           : '#CBCCCD',
  SESAMSEC_SYS_LIGHT_OUTLINE_VARIANT          : '#989898',
  SESAMSEC_SYS_LIGHT_ON_SURFACE_VARIANT       : '#5B5B5B',
  SESAMSEC_SYS_LIGHT_ON_SURFACE_SILENT        : '#838486',
  SESAMSEC_SYS_LIGHT_ON_SURFACE               : '#1E1E1F',
  SESAMSEC_SYS_LIGHT_SECONDARY                : '#269255',
  SESAMSEC_SYS_LIGHT_SURFACE_CONTAINER_LOWEST : '#EAEBEB',
  SESAMSEC_SYS_LIGHT_PRIMARY                  : '#006AB3',
  SESAMSEC_SYS_LIGHT_ON_TERTIARY_CONTAINER    : '#3A2E03',
  SESAMSEC_SYS_LIGHT_TERTIARY_CONTAINER       : '#F9E69F',
  SESAMSEC_SYS_LIGHT_ON_PRIMARY               : '#FFF'

}

export default colors