import { faExclamationTriangle, faFileExport, faFileImport, faIdCard, faLock, faLockOpen, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Card, CardContent, CardMedia, Checkbox, FormControl, Grid, IconButton, InputAdornment, Link, Table as MUITable, MenuItem, Paper, Select, Switch, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { Add as AddIcon, ArrowDownward as ArrowDownwardIcon, ArrowUpward as ArrowUpwardIcon, Cancel as CancelIcon, CheckCircle as CheckCircleIcon, CloseOutlined as CloseOutlinedIcon, Delete as DeleteIcon, Dialpad, Edit as EditIcon, FilterList as FilterListIcon, SearchOutlined as SearchOutlinedIcon, Update as UpdateIcon, WatchLater as WatchLaterIcon, Wifi as WifiIcon } from '@material-ui/icons';
import { ManageAccountsOutlined } from '@mui/icons-material';
import BadgeIcon from '@mui/icons-material/Badge';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ACCESS_POINTS, ACCESS_POINTS_MODULE, ACTION_ADD, ACTION_CREATE, ADMINISTRATORS_MODULE, ADMINISTRATOR_BLOCKED, CHIP_COLOR, CHIP_COMPONENT, CONTROLLERS_MODULE, CREDENTIALS_MODULE, DELETE, EVENTS_MODULE, GET, GROUPS_MODULE, HOLIDAYS_MODULE, HOLIDAY_ITEMS_MODULE, LOCATION, LOCATIONS_MODULE, PATCH, POST, PROFILES, PROFILES_MODULE, ROLES, ROLES_MODULE, SCHEDULES_MODULE, SETUP_MODULE, STANDARD_SCHEDULE, USER, USERS_MODULE, USER_PROVIDER } from '../../utility/constants';
import images from '../../utility/images';
import Chip from '../chip';
import ProgressBar from '../progress-bar';
import useStyles from './styles';
import EmptyTable from './table-empty';
import SkeletonRow from './table-skeleton';

export const createColumn = (id, label, isSortable, type, hidden = false, active = false) => {

  return ({
    id,
    label,
    isSortable,
    type,   //Data type: "string", "boolean", "numeric", "date", "datetime", "time", "currency", "component"
    hidden,
    active,
  });
}

const SortHeader = (props) => {
  const classes = useStyles();
  const {active, columnId, getSortOrderDirection} = props;

  switch(getSortOrderDirection(columnId)){
    case 'asc':
      return (
        <ArrowUpwardIcon
          className={clsx({
            [classes.activeIcon]: active,
            [classes.inactiveIcon]: !active,
          })}
        />
      )
    case 'desc':
      return (
        <ArrowDownwardIcon
          className={clsx({
            [classes.activeIcon]: active,
            [classes.inactiveIcon]: !active,
          })}
        />
      )
    default: 
      return (
        <></>
      )
  }
}

const moduleName = [LOCATION, ROLES, USER, PROFILES, STANDARD_SCHEDULE]

const handleColumnName = (label) => {
  const isModule = moduleName.includes(label);

  if (!isModule) {
    return;
  }

  switch (label) {
    case LOCATION:
      return LOCATIONS_MODULE;
    case ROLES:
      return ROLES_MODULE;
    case USER:
      return USERS_MODULE;
    case PROFILES:
      return PROFILES_MODULE;
    case STANDARD_SCHEDULE:
      return SCHEDULES_MODULE;
    default:
      return label
  }
}

const EnhancedTableHead = (props) => {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, createSortHandler, tableHeads, label, module, skeletonModule, isEmpty, elementIdModule, hasNoActionPermissions, handlePermissions, isAllUserEntraIdUser } = props;
  const hideDefaultActions = (module === EVENTS_MODULE || module === ACCESS_POINTS_MODULE || module === SETUP_MODULE) || hasNoActionPermissions;
  const hideControllerDefault = module === CONTROLLERS_MODULE;
  const hideAction = (skeletonModule === EVENTS_MODULE || skeletonModule === ACCESS_POINTS_MODULE || module === SETUP_MODULE) || hasNoActionPermissions;
  
  const getSortOrder = () => {
    return (order === 'desc') ? 'sorted descending' : 'sorted ascending';
  }

  const getSortDirection = (id) => {
    return (orderBy === id) ? order : false;
  }

  const getSortOrderDirection = (id) => {
    return (orderBy === id) ? order : 'asc'
  }

  const getColumnHeader = (label) => {
    if (module === ACCESS_POINTS_MODULE) {
      return label === 'actions' ? '' : `table-component.${label}`
    } else {
      return label === '' ? '' : `table-component.${label}`
    }
  }

  return (
    <TableHead>
      <TableRow id={`${elementIdModule}TableHead`}>
        <TableCell id={`${elementIdModule}MultiSelectCheckboxCell`} padding="checkbox" className={hideDefaultActions || hideControllerDefault ? 'hidden' : ''}>
          <Checkbox
            id={`${elementIdModule}MultiSelectCheckbox`}
            checked={rowCount > 0 && numSelected === rowCount}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            inputProps={{ "aria-label": `select all ${label}` }}
            onChange={onSelectAllClick}
            disabled={isAllUserEntraIdUser}
          />
        </TableCell>
        <TableCell id={`${elementIdModule}IconCell`} className={clsx(classes.emptyHeader, {
          'hidden': module !== USERS_MODULE
        })}/>
        {
          tableHeads.map((column, index) => {
            let elementIdColumnLabel = column.label.charAt(0).toUpperCase() + column.label.slice(1);
            elementIdColumnLabel = elementIdColumnLabel.replace(/\s+/g, '');
            
            const name = handleColumnName(column.label);
            const hasPermission = handlePermissions(name, GET);

            return (
              <TableCell
                id={`${elementIdModule}${elementIdColumnLabel}Column`}
                key={'col-' + index}
                padding={'normal'}
                sortDirection={getSortDirection(column.id)}
                className={clsx({
                  [classes.cellStyle]: true,
                  'hidden': column.hidden || !hasPermission
                })}
              >
                {
                  column.isSortable ?
                    <TableSortLabel
                      id={`${elementIdModule}${elementIdColumnLabel}Label`}
                      direction={getSortOrderDirection(column.id)}
                      onClick={(event) => createSortHandler(column.id)(event)}
                      hideSortIcon={true}
                    >
                      <Box className={classes.columnHeader}>
                        <Box className={classes.columnItem}>
                          <Trans i18nKey={getColumnHeader(column.label)}/>
                        </Box>
                        <Box>
                          <SortHeader
                            columnId={column.id}
                            active={column.active}
                            getSortOrderDirection={getSortOrderDirection}
                          />
                        </Box>
                      </Box>
                      {
                        orderBy === column.id &&
                        <span className={classes.visuallyHidden}>
                          {getSortOrder()}
                        </span>
                      }
                    </TableSortLabel>
                  :
                    <Box id={`${elementIdModule}${elementIdColumnLabel}Label`} className={classes.columnItem}>
                      <Trans i18nKey={getColumnHeader(column.label)}/>
                    </Box>
                }

              </TableCell>
            )
          })
        }
        {
          (hideDefaultActions || hideAction) ?
            null
          :
            <TableCell id={`${elementIdModule}ActionsColumn`} className={clsx(classes.columnItem, { [classes.visuallyHidden] : hideDefaultActions, [classes.moveCellHead]: isEmpty})}/>
        }
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { numSelected, handleCreate, confirmDelete, handleSearch, handleClearSearch, keyword, label, handleFilter, module, handleMarkAllAsRead, allChecked, openCsvModal, openImportModal, openCreateModal, disabled, handleViewAll, handlePermissions, handleExport, elementIdModule, isLoading } = props;
  const hideFilter            = (module !== EVENTS_MODULE);
  const hideAddButton         = module === EVENTS_MODULE || module === ACCESS_POINTS_MODULE || !handlePermissions(module, POST);
  const hideImportButton      = (module !== USERS_MODULE) || !handlePermissions(module, POST);
  const hideExportButton      = (module !== USERS_MODULE)|| !handlePermissions(module, POST);

  const generateMessage = (text) => {
    return t(`table-component.${text}${label}`);
  }

  const createHoverMessage    = generateMessage(module === CONTROLLERS_MODULE ? ACTION_ADD : ACTION_CREATE);
  const filterHoverMessage    = generateMessage('filter');
  const csvUploadHoverMessage = generateMessage('upload');
  const importHolidayMessage  = generateMessage('import');
  const csvExportMessage      = generateMessage('export');


  const [markAllAsRead, setMarkAllAsRead] = useState(false);
  const [checkState, setCheckState]       = useState(allChecked);
  const isTouched       = markAllAsRead || checkState;
  const isClick         = isTouched ? classes.markAllOffline : classes.online;
  const isClickLabel    = isTouched ? classes.markAsReadLabel : classes.hoverLabel;
  const tooltipMessage  = isTouched ? '' : 'Mark all as Read';

  useEffect(() => {
    setCheckState(allChecked)
  }, [allChecked])

  const handleChangeCheck = () => {
    if (allChecked) return;
    handleMarkAllAsRead();
    setMarkAllAsRead(true);
  }

  if (label === HOLIDAY_ITEMS_MODULE) {
    return (
      numSelected > 0 ?
        <Toolbar className={clsx(classes.root, classes.toolView, {[classes.highlight] : numSelected > 0})}>
          <Typography className={`${classes.title} ${classes.selectionToolbar}`} color="inherit" variant="subtitle1" component="div">
            {numSelected} {t('selected')}
          </Typography>
          <Tooltip 
            title={t('remove')}
            className={clsx(handlePermissions(module, DELETE) ? classes.actionTools : 'hidden')}
          >
            <IconButton id={`${elementIdModule}MultiDeleteButton`} disabled={disabled} aria-label="remove" onClick={(event) => confirmDelete(event, {}, 'multiple')}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      :
        <Toolbar className={clsx(classes.root, classes.toolView, {[classes.highlight] : numSelected > 0})}>
      
          <Grid container className={classes.import}>
            <Tooltip title={`${importHolidayMessage}`} className={clsx({
                [classes.actionTools]   : true,
              })}
            >
              <IconButton id={`${elementIdModule}ImportButton`} component="label" aria-label={`${importHolidayMessage}`} className={classes.iconButton2} onClick={openImportModal} disabled={disabled}>
                <FontAwesomeIcon icon={faFileImport}  />
              </IconButton>
            </Tooltip>
            <Tooltip title={`${createHoverMessage}`} className={clsx({
                [classes.actionTools]   : true,
              })}
            >
              <IconButton id={`${elementIdModule}CreateButton`} component="label" aria-label={`${createHoverMessage}`} className={classes.iconButton3} onClick={openCreateModal} disabled={disabled}>
                <FontAwesomeIcon icon={faPlusSquare} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Box className={clsx({
            [classes.actionTools]   : true,
            [classes.visuallyHidden]: hideFilter,
          })}>
            <Box className={classes.markAsRead} onClick={handleChangeCheck}>
              <Tooltip title={tooltipMessage} className={isClick}>
                <CheckCircleIcon fontSize="small" />
              </Tooltip>
              <Typography variant="caption" className={isClickLabel}><Trans i18nKey={'events-page.markAllAsRead'}/></Typography>
            </Box>
            <Tooltip title={`${filterHoverMessage}`} className={clsx({
              [classes.actionTools]   : false,
              [classes.visuallyHidden]: hideFilter,
            })}
            >
              <IconButton aria-label={`${filterHoverMessage}`} onClick={handleFilter}>
                <FilterListIcon className={classes.iconButton} />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
    );
  }

  return (
    numSelected > 0 ?
      <Toolbar className={clsx(classes.root, classes.toolView, {[classes.highlight] : numSelected > 0})}>
        <Typography className={`${classes.title} ${classes.selectionToolbar}`} color="inherit" variant="subtitle1" component="div">
          {numSelected} {t('selected')}
        </Typography>
        <Tooltip 
          title={t('remove')}
          className={clsx(handlePermissions(module, DELETE) ? classes.actionTools : 'hidden')}
        >
          <IconButton id={`${elementIdModule}MultiDeleteButton`} aria-label="remove" onClick={(event) => confirmDelete(event, {}, 'multiple')}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    :
      <Toolbar className={clsx(classes.root, classes.toolView, {[classes.highlight] : numSelected > 0})}>
        <Box className={classes.searchFieldContainer}>
          <TextField
            className={classes.searchField}
            id={`${elementIdModule}SearchBar`}
            onChange={event => handleSearch(event)}
            placeholder={t('search')}
            value={keyword}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon color="secondary" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    id={`${elementIdModule}ClearSearchBar`}
                    edge="end"
                    onClick={handleClearSearch}
                    size="small"
                    className={
                      clsx({
                        [classes.visuallyHidden]: (keyword === null || keyword === "")
                      })
                    }
                  >
                    <CloseOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Link href="#" onClick={handleViewAll} className={'hidden'}>{`View All ${module}`}</Link>
        </Box>
        <Grid container className={classes.import}>
          <Tooltip title={`${csvUploadHoverMessage}`} className={clsx({
              [classes.actionTools]   : true,
              [classes.visuallyHidden]: hideImportButton,
            })}
          >
            <IconButton id={`${elementIdModule}ImportButton`} component="label" aria-label={`${csvUploadHoverMessage}`} onClick={openCsvModal}>
              <FontAwesomeIcon icon={faFileImport} className={classes.iconButton2} />
            </IconButton>
          </Tooltip>
          <Tooltip title={`${csvExportMessage}`} className={clsx({
              [classes.actionTools]   : true,
              [classes.visuallyHidden]: hideExportButton,
            })}
          >
            <IconButton id={`${elementIdModule}ExportButton`} aria-label={`${csvExportMessage}`} onClick={handleExport}>
              <FontAwesomeIcon className={classes.iconButton2} icon={faFileExport}  />
            </IconButton>
          </Tooltip>
          <Tooltip title={`${createHoverMessage}`} className={clsx({
              [classes.actionTools]   : true,
              [classes.visuallyHidden]: hideAddButton,
            })}
          >
            <IconButton disableRipple disableFocusRipple id={`${elementIdModule}CreateButton`} aria-label={`${createHoverMessage}`} onClick={handleCreate}>
              <AddIcon className={classes.iconButton} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Box className={clsx({
          [classes.actionTools]   : true,
          [classes.visuallyHidden]: hideFilter,
        })}>
          {
            !isLoading ?
              <Box className={classes.markAsRead} onClick={handleChangeCheck}>
                <Tooltip title={tooltipMessage} className={isClick}>
                  <CheckCircleIcon id={`${elementIdModule}MarkAllAsReadIcon`} fontSize="small" />
                </Tooltip>
                <Typography id={`${elementIdModule}MarkAllAsRead`} variant="caption" className={isClickLabel}><Trans i18nKey={'events-page.markAllAsRead'}/></Typography>
              </Box>
            : 
              <></>
          }
          <Tooltip title={`${filterHoverMessage}`} className={clsx({
            [classes.actionTools]   : false,
            [classes.visuallyHidden]: hideFilter,
          })}
          >
            <IconButton id={`${elementIdModule}FilterButton`} aria-label={`${filterHoverMessage}`} onClick={handleFilter}>
              <FilterListIcon className={classes.iconButton} />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
  );
};

const ChipIcon = (props) => {
  const { module, columns, ctr } = props;
  const classes = useStyles();

  if (module === ROLES_MODULE || columns[ctr].id === 'profiles' || module === ADMINISTRATORS_MODULE)
    return  <FontAwesomeIcon icon={faIdCard} className={classes.tableIcon} />
  else if (module === USERS_MODULE)
    return <ManageAccountsOutlined className={classes.tableIcon} />
  else if (columns[ctr].id === 'entries')
    return  <WifiIcon className={classes.tableIcon} />
  else if (module === CONTROLLERS_MODULE)
    return <FontAwesomeIcon icon={faExclamationTriangle} className={classes.tableIcon} />
  else
    return <WatchLaterIcon className={classes.tableIcon} />
}

const RowComponent = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { module, label, row, keys, ctr, handleSwitchStatus, handleView, columns, handlePermissions, handleClick, hideDefaultActions, hideControllerDefault, elementIdModule, elementIdColumn, elementIdRecordId, getRowId } = props;

  const name = handleColumnName(columns[ctr].label);
  const hasPermission = handlePermissions(name, GET);

  switch (module) {
    case CONTROLLERS_MODULE:
      const controller = row[keys[ctr]]

      const isControllerOnboarded = controller.id !== '---' ? true : false;
      
      return (
        <TableCell 
          id={`${elementIdModule}${elementIdRecordId}ControllerCell`} key={'table-cell-' + ctr}
          className={classes.tableCard} 
          onClick={() => handleClick(getRowId(row))}
        >
          <Card className={classes.card}>
            <Link id={`${elementIdModule}${elementIdRecordId}ControllerImage`} href="#" onClick={(event) => handleView(event, row)}>
              <CardMedia
                  component="img"
                  image={controller.image}
                  alt="PASS-Device-Image"
                  className={classes.cardMedia}
                />
            </Link>
            <Box>
              <CardContent>
                <Box className={classes.wordBreak}>
                  <Typography variant="caption" color="primary" className={classes.flexLabel}>{t(`name`)}:</Typography>&nbsp;
                  <Typography variant="caption"><span id={`${elementIdModule}${elementIdRecordId}Name`}>{controller.name}</span></Typography>
                </Box>
                <Box>
                  <Typography variant="caption" className={isControllerOnboarded ? classes.text : classes.textDisabled}>{t(`deviceType`)}: </Typography>
                  <Typography variant="caption" className={!isControllerOnboarded && classes.textDisabled}><span id={`${elementIdModule}${elementIdRecordId}Type`}>{controller.type}</span></Typography>
                </Box>
                <Box>
                  <Typography variant="caption" className={isControllerOnboarded ? classes.text : classes.textDisabled}>{t(`deviceId`)}: </Typography>
                  <Typography variant="caption" className={!isControllerOnboarded && classes.textDisabled}><span id={`${elementIdModule}${elementIdRecordId}DeviceID`}>{controller.id}</span></Typography>
                </Box>
                <Box>
                  <Typography variant="caption" className={isControllerOnboarded ? classes.text : classes.textDisabled}>{t(`serialNumber`)}: </Typography>
                  <Typography variant="caption" className={!isControllerOnboarded && classes.textDisabled}><span id={`${elementIdModule}${elementIdRecordId}SerialNumber`}>{controller.serial}</span></Typography>
                </Box>
              </CardContent>
            </Box>
          </Card>
        </TableCell>
      )

    case CREDENTIALS_MODULE:
    case SETUP_MODULE      :
      return (
        <TableCell key={'table-cell-' + ctr} >
          <Switch
            id={`${elementIdModule}${elementIdRecordId}ActiveSwitch`}
            key={'row-switch-' + ctr}
            checked={row[keys[ctr]]}
            onClick={(event) => handleSwitchStatus(event, row)}
            className={classes.switch}
            disabled={!handlePermissions(module, PATCH)}
          />
        </TableCell>
        )

    case ADMINISTRATORS_MODULE :
    case PROFILES_MODULE       :
    case GROUPS_MODULE         :
    case SCHEDULES_MODULE      :
    case HOLIDAYS_MODULE       :
    case ROLES_MODULE          :
    case USERS_MODULE          :
    case ACCESS_POINTS_MODULE  :
      const chips = row[keys[ctr]]

      return (
        <TableCell
          id={`${elementIdModule}${elementIdRecordId}${elementIdColumn}Cell`}
          key={'table-cell-' + ctr}
          className={clsx({
              [classes.checkboxRow] : !Boolean(hideDefaultActions || hideControllerDefault) || module === USERS_MODULE,
              [classes.row]         : Boolean(hideDefaultActions || hideControllerDefault) && label === HOLIDAY_ITEMS_MODULE,
              [classes.selectRow]   : Boolean(hideDefaultActions || hideControllerDefault) && module !== USERS_MODULE && label !== HOLIDAY_ITEMS_MODULE,
              'hidden'              : !hasPermission
            })
          }
        >
          <Box className={classes.chipset}>
            {
              chips && chips.length > 0 ?
                chips.map((value, index) =>
                  <Box key= {'table-chip-' + index} className={classes.chipsLayout}>
                    <Chip
                      id={`${elementIdModule}${elementIdRecordId}${elementIdColumn}`}
                      recordId={index}
                      icon={<ChipIcon module={module} columns={columns} ctr={ctr} />}
                      title={value.name ? value.name : ''}
                      description={value.description ? value.description : ''}
                      color={CHIP_COLOR.LIGHT}
                    />
                  </Box>
                )
              :
                <>-</>
            }
          </Box>
        </TableCell>
      )

    default: 
      return (
        <></>
      )
  }
}

const EventsAction = (props) => {
  const { row, handleSwitchAction, elementIdModule, elementIdRecordId } = props;
  const classes = useStyles();  
  const { t } = useTranslation();

  const handleChange = (event) => {
    handleSwitchAction(event, row);
  }

  return (
    <Tooltip
      onClick={(event) => handleChange(event)}
      className={`${(row.reader ? classes.online : classes.offline)} ${classes.eventAction}`}
      title={row.reader ? '' : t('table-component.markAsRead')}
    >
      <CheckCircleIcon id={`${elementIdModule}${elementIdRecordId}MarkAsReadButton`} fontSize="small" />
    </Tooltip>
  )
}

const getDisplay = (data) => {
  const id = data.controller?.id;

  return id !== '---' ? true : false;
}

const EnhancedCell = (props) => {
  const classes = useStyles();
  const { t }   = useTranslation();
  const { row, keys, ctr, columns, handleChangeStatus, handleDoorMode, handleSwitchAction, hoverId, module, elementIdModule, elementIdColumn, elementIdRecordId, getRowId } = props;
  const lockStatus = row[keys[ctr]] === true ? <LockIcon/> : <LockOpenIcon/>
  const lockLabel  = row[keys[ctr]] === true ? `${t('requestToOpenDoor')}` : `${t('requestToLockDoor')}`
  const displayed = module === HOLIDAYS_MODULE ? null : getDisplay(row);
  const hideControllerDefault = (module === CONTROLLERS_MODULE);

  const getChipDeviceStateProperty = (property) => {
    switch (property) {
      case 'icon':
        return row[keys[ctr]]?.status ? <CheckCircleIcon fontSize="small" /> : <CancelIcon fontSize="small" />
      case 'description':
        return row[keys[ctr]]?.status ? ' Online' : ' Offline'
      case 'color':  
        return row[keys[ctr]]?.status ? 'online' : 'offline'
      default:
        return;
    }
  }

  const deviceStateIcon        = getChipDeviceStateProperty('icon');
  const deviceStateDescription = getChipDeviceStateProperty('description');
  const deviceStateColor       = getChipDeviceStateProperty('color');
  
  switch (columns[ctr].id) {
    case 'doorAccess': // Boolean

      return (
        getRowId(row) === hoverId &&
        <Tooltip title={lockLabel}>
          <IconButton size="small" aria-label={lockLabel} className={classes.actionButton} onClick={(event) => handleChangeStatus(event, !row[keys[ctr]])}>
            {lockStatus}
          </IconButton>
        </Tooltip>
      )

    case 'doorMode':  // String

      return (
        <FormControl variant="filled" size="small">
          <Select
            id="select-door-mode"
            label="Door Mode"
            labelId="select-door-mode"
            onChange={(event) => handleDoorMode(event, row)}
            value={row[keys[ctr]]}
            className={clsx(
              classes.select,
            {
              [classes.info]   : row[keys[ctr]] === 'normal',
              [classes.light]  : row[keys[ctr]] === 'close',
              [classes.success]: row[keys[ctr]] === 'open',
            })}
            disableUnderline
          >
            <MenuItem value={'normal'}>Normal</MenuItem>
            <MenuItem value={'open'}  >Always Open</MenuItem>
            <MenuItem value={'close'} >Always Closed</MenuItem>
          </Select>
      </FormControl>
      )

    case 'deviceState': // Boolean
      return (
        <Box>
          <Box className={clsx({
            [classes.visible]   : displayed,
            [classes.invisible] : !displayed
          })}>
            <Box >
              <Chip id={elementIdModule} recordId={elementIdRecordId} icon={deviceStateIcon} description={deviceStateDescription} value="with" color={deviceStateColor} variant="outlined" fontSize="small"/>
            </Box>
            <Box className={clsx({[classes.invisible]: row[keys[ctr]].status })}>
              <Chip id={elementIdModule} recordId={elementIdRecordId} description={`${t('unsyncedData')} ${row[keys[ctr]].unsyncCount}`} value="with" variant="outlined" isFor={CHIP_COMPONENT.SYNC} fontSize="small"/>
            </Box>
          </Box>
        </Box>
      )

    case 'read':
      return (
        <EventsAction
          row={row}
          handleSwitchAction={handleSwitchAction}
        />
      )

    case 'deviceVersion': // String
      const version = row[keys[ctr]];

      return (
        <Box>
          <Box className={clsx({
            [classes.visible]   : displayed,
            [classes.invisible] : !displayed
          })}>
            <Box>
              <Typography variant="caption" color="primary">OS {t(`version`)}: </Typography>
              <Typography variant="caption"><span id={`${elementIdModule}${elementIdRecordId}OSVersion`}>{version.OS}</span></Typography>
            </Box>
            <Box>
              <Typography variant="caption" color="primary">SW {t(`version`)}: </Typography>
              <Typography variant="caption"><span id={`${elementIdModule}${elementIdRecordId}SWVersion`}>{version.SW}</span></Typography>
            </Box>
          </Box>
        </Box>
      )

    case 'overallStatus': // String
      const status = row[keys[ctr]];

      return (
        <Box>
          <Box className={clsx({
            [classes.visible]   : displayed,
            [classes.invisible] : !displayed
          })}>
            <ProgressBar elementId={`${elementIdModule}${elementIdRecordId}`} size="small" data={status} deviceType={row.controller.type}/>
          </Box>
        </Box>

      )

    case 'location': // Boolean
      return (
        <Box className={clsx({[classes.invisible]: !displayed && hideControllerDefault})}>
          <span id={`${elementIdModule}${elementIdRecordId}Location`}>{row.location}</span>
        </Box>
      )

    default:
      return (
        columns[ctr].hidden ?
          null
        :
          <span id={`${elementIdModule}${elementIdRecordId}${elementIdColumn}`}>{row[keys[ctr]]}</span>
      )
  }
}

const EnhancedCellData = (props) => {
  const classes = useStyles();
  const {columns, ctr, label, keys, row, module, handleChangeStatus, handleDoorMode, handleClick, handleSwitchAction, handlePermissions, hoverId, getRowId, elementIdModule, elementIdColumn, elementIdRecordId } = props;
  const enableSingleAction = (module === ACCESS_POINTS_MODULE && columns[ctr].label === 'actions');

  return (
    <TableCell
      id={`${elementIdModule}${elementIdRecordId}${elementIdColumn}Cell`}
      className={clsx({
        [classes.row]       : !enableSingleAction && label === HOLIDAY_ITEMS_MODULE,
        [classes.selectRow] : !enableSingleAction && label !== HOLIDAY_ITEMS_MODULE,
        'hidden'            : columns[ctr].hidden || handlePermissions(columns[ctr].label, GET),
        [classes.actionsRow]: enableSingleAction,
        [classes.newActions]: enableSingleAction,
        [classes.nowrapbox] : enableSingleAction,
        [classes.dateColumn]: columns[ctr].type === 'date'
      })}
      key={'table-cell-' + ctr}
      onClick={() => handleClick(getRowId(row))}
      align={enableSingleAction ? 'right' : ''}
    >
      <EnhancedCell
        columns={columns}
        ctr={ctr}
        handleChangeStatus={handleChangeStatus}
        handleSwitchAction={handleSwitchAction}
        handleDoorMode={handleDoorMode}
        hoverId={hoverId}
        keys={keys}
        module={module}
        row={row}
        handlePermissions={handlePermissions}
        elementIdModule={elementIdModule}
        elementIdColumn={elementIdColumn}
        elementIdRecordId={elementIdRecordId}
        getRowId={getRowId}
      />
    </TableCell>
  )
}

const IconComponent = (props) => {
  const { module, elementIdModule, elementIdRecordId, index, row, keys } = props;
  const classes = useStyles();
  const { t }   = useTranslation();

  const getCredentialsIconMessage = (row) => {
    const hasCredential = row.credentials.length > 0;
    const hasPin = row.pin;

    if (hasCredential && hasPin) {
      return t('table-component.pinAndCredentialTooltip');
    } else if (hasCredential && !hasPin) {
      return t('table-component.credentialTooltip');
    } else if (!hasCredential && hasPin) {
      return t('table-component.pinTooltip');
    } else {
      return t('table-component.noPinOrCredentialTooltip');
    }
  }

  switch (module) {
    case USERS_MODULE:
      return (
        <TableCell
          id={`${elementIdModule}${elementIdRecordId}CredentialsIconCell`}
          key={index + '-tc-' + row[keys[0]]}
          className={classes.iconContainer}
        >
          <Tooltip
            title={getCredentialsIconMessage(row)} 
            classes={{
              tooltip: classes.customTooltip
            }}
            className={classes.iconTooltip}
            placement="top"
          >
            <Box>
              {
                row.credentials.length > 0 ?
                  <BadgeIcon className={classes.icon} />
                :
                  <img src={images.BADGE_OFF_ICON} alt='badge-off-icon' className={classes.icon} />
              }
              {
                row.pin > 0 ?
                  <Dialpad className={classes.icon} />
                :
                  <img src={images.DIALPAD_OFF_ICON} alt='dialpad-off-icon' className={classes.icon} />
              }
            </Box>
          </Tooltip>
        </TableCell>
      )
    default:
      return (
        null
      )
  }
}

const EnhancedTableRow = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data, isSelected, columns, label, handleClick, handleDoorMode, handleChangeStatus, handleSwitchStatus, handleUpdateDevice, handleUpdate, handleCheckboxClick, module, confirmDelete, viewKey, handleView, handleSwitchAction, disabled, handlePermissions, hasNoActionPermissions, handleBlock, elementIdModule } = props;
  const hideDefaultActions    = (module === ACCESS_POINTS_MODULE || module === EVENTS_MODULE || module === SETUP_MODULE) || hasNoActionPermissions;
  const hideControllerDefault = (module === CONTROLLERS_MODULE);

  const hasUpdatePermission = handlePermissions(module, PATCH);
  const hasDeletePermission = handlePermissions(module, DELETE);

  const [hoverId, setHoverId] = useState(-1);

  const generateMessage = (text) => {
    return t(`table-component.${text}${label}`);
  }

  const updateHoverMessage    = generateMessage('update');
  const removeHoverMessage    = generateMessage('remove');
  const unblockHoverMessage   = generateMessage('unBlock');
  const blockHoverMessage     = generateMessage('block');

  const getRowId = (row) => {
    switch (module) {
      case CREDENTIALS_MODULE:
        return row.credentialId;
      case HOLIDAYS_MODULE:
        return label === HOLIDAY_ITEMS_MODULE ? row.holidayItemId : row.holidayId;
      case PROFILES_MODULE:
        return row.profileId;
      case SCHEDULES_MODULE:
        return row.scheduleId;
      case USERS_MODULE:
        return row.userId;
      default:
        return row.id;
    }
  }

  return (
    data.map((row, index) => {
      const keys           = Object.keys(row);
      const isItemSelected = isSelected(row);
      const isBlocked      = row.status === t(ADMINISTRATOR_BLOCKED.toLowerCase());
      const isNotBlocked   = row.status !== t(ADMINISTRATOR_BLOCKED.toLowerCase());
      const labelId        = `enhanced-table-checkbox-${index}`;
      const isEntraIdUser  = row.provider === USER_PROVIDER.LDAP ? true : false;
      
      let elementIdRecordId;
      if (label === HOLIDAY_ITEMS_MODULE) {
        elementIdRecordId = row[keys[2]];
      } else if (module === CONTROLLERS_MODULE) {
        elementIdRecordId = row[keys[0]];
      } else {
        elementIdRecordId = row[keys[1]];
      }

      return (
        <TableRow
          id={`${elementIdModule}${elementIdRecordId}`}
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={index + '-tr-' + row[keys[0]]}
          selected={isItemSelected}
          onMouseEnter={() => setHoverId(getRowId(row))}
          onMouseLeave={() => setHoverId(-1)}
        >
          <TableCell id={`${elementIdModule}${elementIdRecordId}CheckboxCell`} padding="checkbox" key={index + '-tc-' + row[keys[0]]}
            className={clsx({
              'hidden': hideDefaultActions || hideControllerDefault
            })}
          >
            {
              module === USERS_MODULE && isEntraIdUser ? 
                <Tooltip
                  title={t('table-component.entraIdUserCheckbox')} 
                  classes={{
                    tooltip: classes.customTooltip
                  }}
                  placement="top"
                >
                  <span>
                    <Checkbox
                      id={`${elementIdModule}${elementIdRecordId}Checkbox`}
                      checked={isItemSelected}
                      color="primary"
                      inputProps={{ "aria-labelledby": labelId }}
                      disabled={isEntraIdUser}
                      onClick={(event) => {
                        if (!Boolean(hideDefaultActions || hideControllerDefault)) {
                          handleCheckboxClick(event, row)
                        }
                      }}
                    />
                  </span>
                </Tooltip>
              :
                <Checkbox
                  id={`${elementIdModule}${elementIdRecordId}Checkbox`}
                  checked={isItemSelected}
                  color="primary"
                  inputProps={{ "aria-labelledby": labelId }}
                  onClick={(event) => {
                    if (!Boolean(hideDefaultActions || hideControllerDefault)) {
                      handleCheckboxClick(event, row)
                    }
                  }}
                />
            }
          </TableCell>
          <IconComponent
            keys={keys}
            module={module}
            row={row}
            elementIdModule={elementIdModule}
            elementIdRecordId={elementIdRecordId}
          />
          {
            keys.map((_, ctr) => {
              if (ctr >= columns.length) {
                return null;
              }
              const elementIdColumn = keys[ctr].charAt(0).toUpperCase() + keys[ctr].slice(1);
              return (
                columns[ctr].type === 'component' ?
                    <RowComponent
                      ctr={ctr}
                      handleSwitchStatus={handleSwitchStatus}
                      handleView={handleView}
                      key={'tr-component-data-' + ctr}
                      keys={keys}
                      module={module}
                      row={row}
                      columns={columns}
                      handlePermissions={handlePermissions}
                      handleClick={handleClick}
                      hideDefaultActions={hideDefaultActions}
                      hideControllerDefault={hideControllerDefault}
                      elementIdModule={elementIdModule}
                      elementIdColumn={elementIdColumn}
                      elementIdRecordId={elementIdRecordId}
                      label={label}
                      getRowId={getRowId}
                    />
                  :
                    <EnhancedCellData
                      columns={columns}
                      ctr={ctr}
                      handleChangeStatus={handleChangeStatus}
                      handleSwitchAction={handleSwitchAction}
                      handleDoorMode={handleDoorMode}
                      handleView={handleView}
                      handleClick={handleClick}
                      hideDefaultActions={hideDefaultActions}
                      hideControllerDefault={hideControllerDefault}
                      hoverId={hoverId}
                      key={'tr-enhanced-cell-data-' + ctr}
                      keys={keys}
                      module={module}
                      row={row}
                      viewKey={viewKey}
                      handlePermissions={handlePermissions}
                      elementIdModule={elementIdModule}
                      elementIdColumn={elementIdColumn}
                      elementIdRecordId={elementIdRecordId}
                      label={label}
                      getRowId={getRowId}
                    />
              )
            })
          }
          {
            hideDefaultActions ?
              null
            :
              <TableCell id={`${elementIdModule}${elementIdRecordId}ActionsCell`} key={index + '-row-action-default-' + row[keys[0]]}
                className={clsx(
                  {
                    [classes.actionsRow]    : !hideDefaultActions,
                    [classes.newActions]    : hideDefaultActions || hideControllerDefault,
                    [classes.nowrapbox]     : !hideDefaultActions,
                    [classes.visuallyHidden]: hideDefaultActions,
                  }
                )}
                align='right'
              >
                {
                  getRowId(row) === hoverId &&
                  <>
                    <Tooltip title={t('update')} className={clsx({ [classes.visuallyHidden]: !hideDefaultActions })}>
                      <IconButton aria-label={`Update`} className={hasUpdatePermission ? classes.actionButton : 'hidden'} onClick={(event) => handleUpdateDevice(event, row)}>
                        <UpdateIcon />
                      </IconButton>
                    </Tooltip>
                      <Tooltip title={updateHoverMessage} className={clsx({ [classes.visuallyHidden]: hideDefaultActions })}>
                        <IconButton id={`${elementIdModule}${elementIdRecordId}UpdateButton`} className={hasUpdatePermission ? classes.actionButton : 'hidden'} disabled={disabled} aria-label={`Update ` + label} onClick={event => handleUpdate(event, row)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    <Tooltip title={blockHoverMessage} className={clsx({[classes.visuallyHidden]: module !== 'Administrators'})}>
                      <IconButton aria-label={`Unblock ` + label} className={clsx({[classes.invisible]: isBlocked, [classes.actionButton]: isNotBlocked})} onClick={(event) => handleBlock(event, row, 'single')} >
                        <FontAwesomeIcon icon={faLockOpen}  className={classes.blockIcon}/>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={unblockHoverMessage} className={clsx({[classes.visuallyHidden]: module !== 'Administrators'})}>
                      <IconButton aria-label={`Block ` + label} className={clsx({[classes.invisible]: isNotBlocked, [classes.actionButton]: isBlocked})} onClick={(event) => handleBlock(event, row, 'single')}>
                        <FontAwesomeIcon icon={faLock}  className={classes.blockIcon}/>
                      </IconButton>
                    </Tooltip>
                      <Tooltip title={removeHoverMessage} className={clsx({ [classes.visuallyHidden]: hideDefaultActions || hideControllerDefault})}>
                        <IconButton id={`${elementIdModule}${elementIdRecordId}RemoveButton`}  className={hasDeletePermission && !isEntraIdUser ? classes.actionButton : 'hidden'} disabled={disabled} aria-label={`Remove ` + label} onClick={(event) => confirmDelete(event, row, 'single')}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                  </>
                }
              </TableCell>
          }
        </TableRow>
      )
    })
  )
}

const EnhancedTableData = (props) => {
  const { data, columns, label, isSelected, handleDoorMode, handleClick, handleChangeStatus, handleCheckboxClick, handleUpdateDevice, handleSwitchStatus, handleUpdate, confirmDelete, handleView, viewKey, module, handleSwitchAction, disabled, handlePermissions, handleBlock, elementIdModule, hasNoActionPermissions } = props;
  const classes = useStyles();

  return (
    <TableBody className={classes.tableBody}>
      <EnhancedTableRow
        disabled={disabled}
        columns={columns}
        confirmDelete={confirmDelete}
        handleBlock={handleBlock}
        data={data}
        handleChangeStatus={handleChangeStatus}
        handleClick={handleClick}
        handleDoorMode={handleDoorMode}
        handleSwitchStatus={handleSwitchStatus}
        handleUpdate={handleUpdate}
        handleUpdateDevice={handleUpdateDevice}
        handleSwitchAction={handleSwitchAction}
        handleView={handleView}
        isSelected={isSelected}
        label={label}
        module={module}
        viewKey={viewKey}
        handlePermissions={handlePermissions}
        hasNoActionPermissions={hasNoActionPermissions}
        elementIdModule={elementIdModule}
        handleCheckboxClick={handleCheckboxClick}
      />
    </TableBody>
  )
}

const EnhancedTable = (props) => {
  const { columns, confirmDelete, data, handleDoorMode, handleClick, handleCheckboxClick, handleChangeStatus, handleUpdateDevice, handleRequestSort, createSortHandler, tableHeads, handleSelectAllClick, handleSwitchStatus, handleUpdate, handleView, isSelected, label, module, order, orderBy, selected, viewKey, keyword, handleSwitchAction, disabled, handlePermissions, hasNoActionPermissions, handleBlock, elementIdModule } = props;
  const classes = useStyles();

  const isAllUserEntraIdUser = () => {
    return data.every(user => user.provider === USER_PROVIDER.LDAP);
  }

  return (
    data.length > 0 ?
      <MUITable
        className={classes.table}
        aria-labelledby="tableTitle"
        aria-label="enhanced table"
      >
        <EnhancedTableHead
          classes={classes}
          label={label}
          module={module}
          numSelected={selected.length}
          tableHeads={tableHeads}
          createSortHandler={createSortHandler}
          onSelectAllClick={handleSelectAllClick}
          order={order}
          orderBy={orderBy}
          rowCount={data.length}
          skeletonModule={module}
          elementIdModule={elementIdModule}
          hasNoActionPermissions={hasNoActionPermissions}
          handlePermissions={handlePermissions}
          isAllUserEntraIdUser={isAllUserEntraIdUser()}
        />

          <EnhancedTableData
            disabled={disabled}
            columns={columns}
            confirmDelete={confirmDelete}
            handleBlock={handleBlock}
            data={data}
            handleChangeStatus={handleChangeStatus}
            handleCheckboxClick={handleCheckboxClick}
            handleClick={handleClick}
            handleDoorMode={handleDoorMode}
            handleRequestSort={handleRequestSort}
            handleSelectAllClick={handleSelectAllClick}
            handleSwitchStatus={handleSwitchStatus}
            handleUpdate={handleUpdate}
            handleUpdateDevice={handleUpdateDevice}
            handleSwitchAction={handleSwitchAction}
            handleView={handleView}
            isSelected={isSelected}
            label={label}
            module={module}
            order={order}
            orderBy={orderBy}
            selected={selected}
            viewKey={viewKey}
            handlePermissions={handlePermissions}
            hasNoActionPermissions={hasNoActionPermissions}
            elementIdModule={elementIdModule}
          />
      </MUITable>
    :
      <TableContainer>
        <MUITable
          aria-label="enhanced table"
          aria-labelledby="tableTitle"
          className={classes.table}
        >
          <EnhancedTableHead
            classes={classes}
            label={label}
            numSelected={selected.length}
            createSortHandler={createSortHandler}
            tableHeads={tableHeads}
            onSelectAllClick={handleSelectAllClick}
            order={order}
            orderBy={orderBy}
            rowCount={data.length}
            skeletonModule={module}
            isEmpty={Boolean(!data.length)}
            elementIdModule={elementIdModule}
            hasNoActionPermissions={hasNoActionPermissions}
            handlePermissions={handlePermissions}
            module={module}
          />
        </MUITable>
        <EmptyTable
          keyword={keyword}
          label={label}
        />
      </TableContainer>
  )
}

const Table = (props) => {
  const { columns, page, rowsPerPage, data, isLoading, label, module, onChangeAction, onChangeDoorMode, onChangePage, onChangeStatus, onRowsPerPageChange, onCreate, onDelete, onMultipleDelete, onClearSearch, onSearch, onSort, onUpdate, onUpdateDevice, onView, keyword, totalItems, order, orderBy, viewKey, onFilter, onMarkAsRead, onMarkAllAsRead, allChecked, handleUpload, csv, onOpenModal, onOpenImportModal, onOpenCreateModal, disabled, onBlock, onViewAll, setAreItemsSelected, handleExport, handlePermissions } = props;
  const classes                       = useStyles();
  const { t }                         = useTranslation();
  const history                       = useHistory();
  const [selected, setSelected]       = useState([]);
  const [tableHeads, setTableHeads]   = useState(columns);

  const columnKeys = Object.keys(columns.filter(column => !column.hidden));

  const hasNoActionPermissions = !handlePermissions(module, DELETE) && !handlePermissions(module, PATCH);

  let elementIdModule = module.charAt(0).toLowerCase() + module.slice(1);
  elementIdModule = elementIdModule.replace(/\s+/g, '');

  const handleCreate = () => {
    onCreate();
  }

  const handleUpdate = (event, item) => {
    event.stopPropagation();
    onUpdate(item);
  }

  const confirmDelete = (event, item, type) => {
    event.stopPropagation();
    event.preventDefault();
    if (type === 'single') onDelete(item);
    else onMultipleDelete(selected);
    setSelected([]);
  }

  const handleBlock = (event, item, type) => {
    event.stopPropagation();
    event.preventDefault();
    onBlock(event, item, type);
  }

  const handleFilter = () => {
    onFilter();
    setSelected([]);
  }

  const handleSearch = (event) => {
    event.stopPropagation();
    onSearch(event.target.value);
  }

  const handleClearSearch = () => {
    onClearSearch();
  }

  const handleRequestSort = (event, newOrderBy) => {
    event.stopPropagation();
    const newOrder = order === 'asc' ? 'desc' : 'asc';
    onSort(newOrderBy, newOrder);
    setSelected([]);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
    const index = columns.findIndex(obj => obj.id === property)
    columns.map((column) => {
      column.active = false
      return null
    })
    columns[index].active = true

    setTableHeads(columns);
  };

  const compareProperties = (selectedUser, data) => (
    typeof selectedUser === 'object' && Object.keys(selectedUser).length > 0 ? 
        Object.keys(selectedUser).length === Object.keys(data).length 
        &&
        Object.keys(selectedUser).every(subProperty => compareProperties(selectedUser[subProperty], data[subProperty]))
      :
        selectedUser === data
  );

  const handleSelectAllClick = (event) => {
    event.stopPropagation();
    const newSelecteds = data.filter((n) => n.provider !== USER_PROVIDER.LDAP);
    if (module === USERS_MODULE && compareProperties(selected, newSelecteds)) {
      setSelected([]);
      return;
    }
    
    if (event.target.checked) {
      setSelected(newSelecteds);
      label === HOLIDAY_ITEMS_MODULE &&  setAreItemsSelected(newSelecteds.length);
      return;
    }
    label === HOLIDAY_ITEMS_MODULE && setAreItemsSelected(0);
    setSelected([]);
  };

  const handleClick = (id) => {
    if (label === HOLIDAY_ITEMS_MODULE) {
      return;
    }

    let modulePath = module.toLowerCase().replace(/ /g, '');

    if (module === ACCESS_POINTS_MODULE) {
      modulePath = ACCESS_POINTS;
    }
    
    history.push(`../${modulePath}/view/${id}`)
  };

  const handleCheckboxClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    label === HOLIDAY_ITEMS_MODULE && setAreItemsSelected(newSelected.length);
    setSelected(newSelected);
  };

  const handleChangeStatus = (event, newValue) => {
    event.stopPropagation();
    onChangeStatus(newValue);
    setSelected([]);
  }

  const handleChangePage = (event, newPage) => {
    onChangePage(newPage);
    setSelected([]);
  };

  const handleRowsPerPageChange = (event) => {
    onRowsPerPageChange(event.target.value);
  }

  const handleSwitchStatus = (event, row) => {
    event.stopPropagation();
    onChangeAction(row, event.target.checked);
    setSelected([]);
  }

  const handleView = (event, row) => {
    event.preventDefault();
    event.stopPropagation();

    onView(row);
  }

  const handleUpdateDevice = (event, row) => {
    event.stopPropagation();
    onUpdateDevice(row);
    setSelected([]);
  }

  const handleDoorMode = (event, row) => {
    event.stopPropagation();
    onChangeDoorMode(event.target.value, row);
    setSelected([]);
  }

  const handleSwitchAction = (event, row) => {
    event.stopPropagation();
    onMarkAsRead(row, event.target);
    setSelected([]);
  }

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleChangeFile  = () => {
    handleUpload();
  }

  const openCsvModal = () => {
    onOpenModal();
  }

  const handleViewAll = (event) => {
    event.preventDefault();
    onViewAll();
  }
  
  const openImportModal = () => {
    onOpenImportModal();
  }

  const openCreateModal = () => {
    onOpenCreateModal();
  }

  const countElement = (count, to) => {
    return count !== -1 ? count : `more than ${to}`
  }

  const countTableItems = (from, to) => {
    from = to === 0 ? 0 : from;
    return `${from}–${to} ${t('of')}`;
  }
  

  const getDisabledProp = () => {
    return {
      disabled: isLoading
    }
  }

  const getCustomProp = () => {
    return isLoading && getDisabledProp()
  }
  
  return (
    <Paper className={clsx(classes.paper, classes.cutBar)} elevation={label !== HOLIDAY_ITEMS_MODULE && 3}>
      <EnhancedTableToolbar
        isLoading={isLoading}
        confirmDelete={confirmDelete}
        handleBlock={handleBlock}
        handleClearSearch={handleClearSearch}
        handleCreate={handleCreate}
        handleFilter={handleFilter}
        handleSearch={handleSearch}
        handleMarkAllAsRead={onMarkAllAsRead}
        allChecked={allChecked}
        keyword={keyword}
        label={label}
        module={module}
        numSelected={selected.length}
        handleChangeFile={handleChangeFile}
        csv={csv}
        openCsvModal={openCsvModal}
        handleViewAll={handleViewAll}
        openImportModal={openImportModal}
        openCreateModal={openCreateModal}
        disabled={disabled}
        handlePermissions={handlePermissions}
        handleExport={handleExport}
        elementIdModule={elementIdModule}
      />
      {
        isLoading ?
          <TableContainer className={classes.toolView}>
            <MUITable
              aria-label="enhanced table"
              aria-labelledby="tableTitle"
              className={classes.table}
            >
              <EnhancedTableHead
                classes={classes}
                label={label}
                numSelected={selected.length}
                tableHeads={tableHeads}
                createSortHandler={createSortHandler}
                onSelectAllClick={handleSelectAllClick}
                order={order}
                orderBy={orderBy}
                rowCount={data.length}
                skeletonModule={module}
                hasNoActionPermissions={hasNoActionPermissions}
                handlePermissions={handlePermissions}
                module={module}
              />
            <SkeletonRow
              columnKeys={columnKeys}
              columns={columns}
              ROWS_PER_PAGE={rowsPerPage}
              module={module}
              handlePermissions={handlePermissions}
              hasNoActionPermissions={hasNoActionPermissions}
              handleColumnName={handleColumnName}
            />
            </MUITable>
          </TableContainer>
        :
          <TableContainer className={classes.toolView}>
            <EnhancedTable
              disabled={disabled}
              columns={columns}
              confirmDelete={confirmDelete}
              handleBlock={handleBlock}
              data={data}
              handleChangeStatus={handleChangeStatus}
              handleCheckboxClick={handleCheckboxClick}
              handleClick={handleClick}
              handleDoorMode={handleDoorMode}
              handleRequestSort={handleRequestSort}
              tableHeads={tableHeads}
              createSortHandler={createSortHandler}
              handleSelectAllClick={handleSelectAllClick}
              handleSwitchStatus={handleSwitchStatus}
              handleUpdate={handleUpdate}
              handleUpdateDevice={handleUpdateDevice}
              handleSwitchAction={handleSwitchAction}
              handleView={handleView}
              isSelected={isSelected}
              keyword={keyword}
              label={label}
              module={module}
              order={order}
              orderBy={orderBy}
              selected={selected}
              viewKey={viewKey}
              handlePermissions={handlePermissions}
              hasNoActionPermissions={hasNoActionPermissions}
              elementIdModule={elementIdModule}
            />
          </TableContainer>
      }
      <TablePagination
        id={`${elementIdModule}Pagination`}
        className={classes.pagination}
        component="div"
        labelRowsPerPage={t('rowPerPage')}
        labelDisplayedRows={function defaultLabelDisplayedRows({ from, to, count }) { return `${countTableItems(from, to)} ${countElement(count, to)}`; }}
        count={totalItems}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleRowsPerPageChange}
        page={page - 1}
        rowsPerPage={rowsPerPage > 100 ? 100 : rowsPerPage}
        rowsPerPageOptions={[10, 15, 25, 50, 75, 100]}
        SelectProps={getDisabledProp()}
        backIconButtonProps={getCustomProp()}
        nextIconButtonProps={getCustomProp()}
      />
    </Paper>
  );
}

export default Table;
