import { Box, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import useStyles from './styles';

const ControllerSkeleton = (props) => {
  const classes = useStyles()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
        <Skeleton className={classes.image} />
        <Box className={classes.properties}>
          <Skeleton height={60} width={240} className={classes.textTitle} />
          <Skeleton height={30} width={180} />
          <Skeleton height={30} width={220} />
          <Skeleton height={30} width={190} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={8} lg={3} xl={2}>
        <Box className={classes.details}>
          <Skeleton height={60} width={240} className={classes.textTitle} />
          <Skeleton height={30} width={200} />
          <Skeleton height={30} width={170} />
          <Skeleton height={30} width={230} />
          <Skeleton height={30} width={190} />
          <Skeleton height={30} width={180} />
        </Box>
      </Grid>
      <Grid component={Box} item md={4} className={classes.fakeGrid} sx={{ display: { xs: "none", sm: "none", md: "block", lg: "none", xl: "none" } }}></Grid>
      <Grid item xs={12} sm={12} md={8} lg={6} xl={3}>
        <Skeleton height={60} width={240} className={classes.textTitle} />
        <Box className={classes.accessPointsContainer}>
          <Skeleton height={60} />
          <Skeleton height={60} />
          <Skeleton height={60} />
        </Box>
      </Grid>
    </Grid>
  )
}

export default ControllerSkeleton;