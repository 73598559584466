import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  chip: {
    borderRadius: theme.spacing(1),
    height: theme.spacing(3.25),
    maxWidth: 230,
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiChip-deleteIcon': {
      color: colors.BLACK
    },
    '& .MuiChip-label': {
      lineHeight: 1
    },
    '& :hover': {
      cursor: 'pointer'
    }
  },
  dropDownMenu: {
    '& .MuiPopover-paper': {
      width: 215,
      maxHeight: 200
    },
    '& .MuiListItem-button:hover': {
      backgroundColor: colors.LIGHT_GRAY
    },
    '& .MuiListItem-button': {
      fontSize: theme.spacing(1.75)
    },
  }
}))