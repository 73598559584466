import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  paper: {
    width       : theme.spacing(75),
    borderRadius: theme.spacing(1.25)
  },
  dialogTitleContainer: {
    alignItems    : 'center',
    display       : 'flex',
    justifyContent: 'space-between',
    padding       : theme.spacing(2),
    paddingLeft   : theme.spacing(3),
  },
  dialogTitle: {
    fontWeight: 'bold',
    fontSize  : theme.spacing(2.75)
  },
  dialogActions: {
    alignItems     : 'center',
    backgroundColor: colors.LIGHT_GRAY,
    display        : 'flex',
    gap            : theme.spacing(2),
    justifyContent : 'flex-end',
    padding        : theme.spacing(3),
    '& .MuiButton-root': {
      color: colors.PRIMARY
    },
    '& .MuiButton-contained': {
      background: colors.PRIMARY,
      color: colors.WHITE
    }
  },
  dialogButton: {
    borderRadius: theme.spacing(1),
    display     : 'flex',
    maxWidth    : theme.spacing(32.25),
    minWidth    : theme.spacing(7),
    padding     : theme.spacing(1.25),
    paddingLeft : theme.spacing(2),
    paddingRight: theme.spacing(2),
    width       : 'fit-content',
  },
}));