import { Grid } from '@material-ui/core';
import { TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectItems from '../../../components/select-items';
import { API_REQUEST_ERROR_MESSAGE, MAX_CHARACTER_LIMIT } from '../../../utility/constants';
import { controllerDetailsSchema } from '../../../validation/schema';
import ActionModal from '../../../components/controller-modal';
import useStyles from './styles';
import { updateController } from '../../../service/controllersApi';

/**
 * @param {object} props - The `UpdateControllerModal` component accepts the following props:
 * @property {boolean} isOpen - Controls the visibility of the modal. 
 * @property {number} controllerId - Control ID. 
 * @property {function} onClose - Callback triggered when the modal is closed.
 * @property {string} name - The current name of the controller, displayed in the text field.
 * @property {array} selectedLocation - The initial location arry of object selected for the controller.
 * @property {function} handlePermissions - Callback function to manage permissions for location selection.
 * @property {function} showToaster - Function to display a toast message, typically used to show feedback to the user.
 * @returns The `UpdateControllerModal` component renders a modal that allows the user to update the name and location of a controller. 
 * It includes a text field for the name, a location selector, and action buttons for submission or cancellation.
 */
const ControllerUpdateModal = ({ 
  isOpen, 
  onClose, 
  name, 
  selectedLocation, 
  handlePermissions, 
  showToaster,
  controllerId,
  showLoading
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formikRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  if (!isOpen) return null;

  const initialValues = {
    name: name || '',
    location: selectedLocation || null,
  };

  const getUpdateData = (values) => {
    const { locationId } = values.location[0];
    if (values.name !== name) {
      return {
        name       : values.name,
        locationId: locationId
      }
    } else {
      return { locationId }
    }
  }
  
  const handleFormSubmit = async (values) => {
    const data = getUpdateData(values);
    setIsLoading(true);
    try {
      await updateController(controllerId, data);
  
      showToaster(t('success'), t('controller-modal.update-controller-updated-success', { name: values.name }), 'success');
      onClose(values);
    } catch (error) {
      const err = error.response;
      if (err && err.status === 409) {
        if (err.data.error === 'username') {
          formikRef.current?.setErrors({
            name: t('controller-modal.update-controller-existing-username-error', {
              username: values.name.replace(/[^a-zA-Z0-9 ]/g, ''),
            }),
          });
        } else {
          formikRef.current?.setErrors({
            name: t('controller-modal.update-controller-existing-name-error'),
          });
        }
      } else {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
      }
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('controller-modal.update-controller-title')}
      cancelButtonLabel={t('controller-modal.cancel-button')}
      submitButtonLabel={t('controller-modal.save-button')}
      handleSubmit={() => formikRef.current?.handleSubmit()}
    >
      { showLoading(isLoading) }
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={controllerDetailsSchema}
        onSubmit={handleFormSubmit}
        innerRef={formikRef}
      >
        {({ values, touched, errors, handleChange }) => (
          <Form>
            <Grid container spacing={2} className={classes.form} data-testid="controllerDetailsContainer">
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  inputProps={{ maxLength: MAX_CHARACTER_LIMIT.TEXT_FIELD }}
                  label={`${t('controller-modal.update-controller-name-Label')}*`}
                  name="name"
                  variant="outlined"
                  value={values.name}
                  onChange={handleChange}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && t(errors.name)}
                  data-testid="controllerName"
                />
              </Grid>
              <Grid item xs={12}>
                <SelectItems
                  data-testid="controllerLocation"
                  helperText={touched.location && t(errors.location)}
                  isValid={touched.location && Boolean(errors.location)}
                  name="Locations"
                  onChange={(value) => handleChange({ target: { name: 'location', value } })}
                  selectedItems={values.location}
                  showToaster={showToaster}
                  single
                  required
                  handlePermissions={handlePermissions}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </ActionModal>
  );
};

ControllerUpdateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  selectedLocation: PropTypes.array.isRequired,
  handlePermissions: PropTypes.func.isRequired,
  controllerId: PropTypes.number.isRequired,
  showToaster: PropTypes.func.isRequired,
  showLoading: PropTypes.func.isRequired,
};

export default ControllerUpdateModal;